import React from 'react';
import {
    Create,
    Datagrid,
    DeleteButton,
    FileField, FileInput,
    List,
    SimpleForm,
    useTranslate
} from 'react-admin';
import BackButton from '../components/BackButton';
import { API_URL } from '../variables/constant';


export const GuideConducteur3List = props => {
    console.log(``)
    const translate = useTranslate();
    return (
        <List {...props}
              filter={{ guideConducteur3: { "neq": null } }}
              sort={{ field: "updatedAt", order: "DESC" }}>
            <Datagrid>
                <FileView guide="guideConducteur3"/>
                <DeleteButton />
            </Datagrid>
        </List>
    )
};

const FileView = (props) => {
    return (
        <div>
        <a href={`${API_URL}/Containers/pdf/download/${props.record.guideConducteur3}`}>{props.record.guideConducteur3}</a>
        </div>
    )
}

const CustomForm = (props) => {
    const translate = useTranslate();
    return (
        <div className="w-100">
            <div className="d-flex flex-wrap">
                <div className="col-12">
                    <FileInput source="guideConducteur3" label="Related files" accept="application/pdf">
                        <FileField source="guideConducteur3" title="guideConducteur3" />
                    </FileInput>
                </div>
            </div>
        </div>
    )
};

export const GuideConducteur3Create = (props: any) => {
    const translate = useTranslate();
    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mt-3">
                <BackButton/>
                <h3>{translate('guide.create')}</h3>
                <b></b>
            </div>
            <Create {...props}>
                <SimpleForm redirect="/GuideConducteur3">
                    <CustomForm/>
                </SimpleForm>
            </Create>
        </div>
    );
};

