import React from "react";
import {
  AutocompleteInput,
  Create,
  Datagrid,
  DateField,
  DateInput,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useTranslate,
} from "react-admin";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { AccountType } from "../react-admin-loopback/api/shared-constants";
import BackButton from "../components/BackButton";

const TracteFilter = (props: any) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <ReferenceInput
        source="accountId"
        perPage= { null }
        sort={{ field: "nom", order: "ASC" }}
        reference="Accounts"
        alwaysOn
        filter={{ type: AccountType.CONDUCTEUR }}
      >
        <SelectInput optionText="nom" />
        {/*<AutocompleteInput optionText="nom"/>*/}
      </ReferenceInput>

      <TextInput source="typeTracte" alwaysOn />
    </Filter>
  );
};

export const CustomForm = (props) => {
  const translate = useTranslate();
  return (
    <div className="w-100">
      <div className="d-flex flex-wrap">
        <div className="col-12 col-sm-6">
          <ReferenceInput
            source="accountId"
            reference="Accounts"
            perPage = { null }
            sort={{ field: "nom", order: "ASC" }}
            label={translate("commons.driver")}
            filter={{ type: AccountType.CONDUCTEUR }}
            fullWidth
          >
            <SelectInput optionText="nom" />
          </ReferenceInput>
        </div>
        <div className="col-12 col-sm-6">
          <TextInput source="parcTracte" fullWidth />
        </div>
        <div className="col-12 col-sm-6">
          <TextInput source="matImTracte" fullWidth />
        </div>
        <div className="col-12 col-sm-6">
          <TextInput source="donneeSpecif" fullWidth />
        </div>
        <div className="col-12 col-sm-6">
          <TextInput source="typeTracte" fullWidth />
        </div>
        <div className="col-12 col-sm-6">
          <TextInput source="etatChargeVide" fullWidth />
        </div>
      </div>
    </div>
  );
};
export const TracteList = (props) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      filters={<TracteFilter />}
      exporter={false}
      sort={{ field: "id", order: "DESC" }}
    >
      <Datagrid>
        <TextField source="id" />
        <ReferenceField source="accountId" reference="Accounts">
          <TextField source="nom" />
        </ReferenceField>
        <TextField source="parcTracte" />
        <TextField source="matImTracte" />
        <TextField source="donneeSpecif" />
        <TextField source="typeTracte" />
        <TextField source="etatChargeVide" />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export const TracteCreate = (props: any) => {
  const translate = useTranslate();
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mt-3">
        {/* <Link to="/Tractes" className="text-decoration-none">
          <ArrowBackIcon /> {translate("button.back")}
        </Link> */}
        <BackButton/>
        <h3>{translate("tractes.create")}</h3>
        <b></b>
      </div>
      <Create {...props}>
        <SimpleForm redirect="/Tractes">
          <CustomForm />
        </SimpleForm>
      </Create>
    </div>
  );
};

export const TracteEdit = (props: any) => {
  const translate = useTranslate();
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mt-3">
        {/* <Link to="/Tractes" className="text-decoration-none">
          <ArrowBackIcon /> {translate("button.back")}
        </Link> */}
        <BackButton/>
        <h3>{translate("tractes.edit")}</h3>
        <b></b>
      </div>
      <Edit {...props}>
        <SimpleForm>
          <CustomForm />
        </SimpleForm>
      </Edit>
    </div>
  );
};
