import { makeStyles } from "@material-ui/core/styles";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import React from "react";
import {
  Create,
  Datagrid,
  DateField,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useTranslate
} from "react-admin";
import { Link } from "react-router-dom";
import BackButton from "../components/BackButton";
import { AccountType } from "../react-admin-loopback/api/shared-constants";

const MissionFilter = (props: any) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <ReferenceInput
        source="accountId"
        perPage= { null }
        sort={{ field: "nom", order: "ASC" }}
        reference="Accounts"
        label={translate("commons.name")}
        alwaysOn
      >
        <SelectInput optionText="nom" />
      </ReferenceInput>
      <TextInput source="ot" alwaysOn />
    </Filter>
  );
};

export const CustomForm = (props) => {
  const translate = useTranslate();
  return (
    <div className="w-100">
      <div className="d-flex flex-wrap">
        <div className="col-12 col-sm-6">
          <TextInput source="ot" label={translate("commons.ot")} fullWidth />
        </div>
        {/*<div className="col-12 col-sm-6">*/}
        {/*<TextInput source="moteur" label={translate('commons.moteur')} fullWidth/>*/}
        {/*</div>*/}
        {/*<div className="col-12 col-sm-6">*/}
        {/*<TextInput source="tracte" label={translate('commons.tracte')} fullWidth/>*/}
        {/*</div>*/}
        <div className="col-12 col-sm-6">
          <ReferenceInput
            source="accountId"
            perPage= { null }
            reference="Accounts"
            label={translate("commons.driver")}
            sort={{ field: "nom", order: "ASC" }}
            filter={{ type: AccountType.CONDUCTEUR }}
            fullWidth
          >
            <SelectInput optionText="nom" />
          </ReferenceInput>
        </div>
      </div>
    </div>
  );
};
const HaveEyeIconField = (props) => {
  const useStyles = makeStyles({
    iconEyes: {
      color: "rgb(204, 204, 0)",
      fontSize: 32,
    },
  });
  const classes = useStyles();
  return (
    <Link to={`/Etapes?missionId=${props.record.id}`}>
      <VisibilityOutlinedIcon className={classes.iconEyes} />
    </Link>
  );
};
export const MissionList = (props) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      filters={<MissionFilter />}
      exporter={false}
      sort={{ field: "id", order: "DESC" }}
    >
      <Datagrid>
        <TextField source="id" />
        <TextField source="ot" label={translate("commons.ot")} />
        <ReferenceField
          source="accountId"
          reference="Accounts"
          label={translate("commons.driver")}
        >
          <TextField source="nom" />
        </ReferenceField>
        <DateField source="createdAt" label={translate("commons.createdAt")} />
        <DateField source="updatedAt" label={translate("commons.updatedAt")} />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export const MissionCreate = (props: any) => {
  const translate = useTranslate();
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mt-3">
        <BackButton/>
        <h3>{translate("missions.create")}</h3>
        <b></b>
      </div>
      <Create {...props}>
        <SimpleForm redirect="/Missions">
          <CustomForm />
        </SimpleForm>
      </Create>
    </div>
  );
};

export const MissionEdit = (props: any) => {
  const translate = useTranslate();
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mt-3">
        {/* <Link to="/Missions" className="text-decoration-none">
          <ArrowBackIcon /> {translate("button.back")}
        </Link> */}
        <BackButton/>
        <h3>{translate("missions.edit")}</h3>
        <b></b>
      </div>
      <Edit {...props}>
        <SimpleForm>
          <CustomForm />
        </SimpleForm>
      </Edit>
    </div>
  );
};
