import React, { useEffect, useState } from 'react';
import {
    Create,
    Datagrid, DateField, DeleteButton, Edit, EditButton, Filter, FormDataConsumer, ImageField, ImageInput,
    List,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput, translate, useTranslate
} from 'react-admin';
import { AccountType, ParameterType } from '../react-admin-loopback/api/shared-constants';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { parse } from 'query-string';
import { LoopbackAPI } from '../react-admin-loopback/api/loopback-api';
import { withTypes } from 'react-final-form';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
// import { Parameter } from '../react-admin-loopback/api/api';
import { useNotify, useRedirect } from 'ra-core';
import BackButton from '../components/BackButton';

const ParameterFilter = (props: any) => (
    <Filter {...props}>
        <TextInput source="name" alwaysOn label="Nom"/>
        <ReferenceInput source="societeId" reference="Societes" alwaysOn>
            <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

export const ParameterList = props => {
    const translate = useTranslate();
    const { societeId: post_id_string } = parse(props.location.search);
    const societeId = post_id_string ? parseInt(post_id_string as string, 10) : 0;
    return (
        <List
            {...props}
            filters={<ParameterFilter />}
            filter = { societeId > 0 ? {societeId} : {} }
            sort={{ field: "updatedAt", order: "DESC" }}
        >
            <Datagrid>
                <TextField source="id" />
                <TextField source="name" label={translate('commons.name')}/>
                <TextField source="value" label={translate('commons.value')}/>
                <TextField source="description" label={translate('commons.description')} />
                <ReferenceField source="societeId" reference="Societes" label={translate('commons.societe')}>
                    <TextField source="name"/>
                </ReferenceField>
                <DateField source="createdAt" label={translate('commons.createdAt')}/>
                <DateField source="updatedAt" label={translate('commons.updatedAt')}/>
                <EditButton />
                <DeleteButton undoable={false} />
            </Datagrid>
        </List>
    )
};

interface FormValues {
    name?: string;
    societeId?: number;
    value?: any;
    description?: string;
    latestVersion?: string;
}
const { Form } = withTypes<FormValues>();

const CustomForm = (props) => {
    const translate = useTranslate();
    const [loading, setLoading] = useState(false);
    const [currentParam, setCurrentParam] = useState<any>();
    const notify = useNotify();
    const redirect = useRedirect();
    const params = Object.keys(ParameterType).map(it => {
        return { id: it, name: ParameterType[it]}
    });
    useEffect(() => {
        (async () => {
            const API = new LoopbackAPI();
            if (props?.record?.id) {
                // const param = await API.parameters.parameterFindById(props.record.id);
                // try {
                //     if (param.name === ParameterType.LAST_VERSION && param.value) {
                //         param.value = JSON.parse(param.value);
                //     }
                // } catch (e){}
                // setCurrentParam(param);
            }
        })()
    }, []);
    const handleSubmit = async (values: FormValues) => {
        setLoading(true);
        let newValue = values.value;
        if (values.latestVersion && values.latestVersion.link) {
            newValue = JSON.stringify(values.latestVersion);
        }
        const API = new LoopbackAPI();
        // const newParameter: Parameter = {
        //     name: values.name,
        //     societeId: values.societeId,
        //     value: newValue,
        //     description: values.description
        // }
        // if (props?.record?.id) {
        //     newParameter.id = props.record.id;
        // }
        // await API.parameters.parameterPatchOrCreate(newParameter);
        setLoading(false);
        notify('messages.elementCreated');
        redirect('/Parameters');
    };
    return (
        <Form
            onSubmit={handleSubmit}
            render={({ handleSubmit }) => (
                <form className="w-100" onSubmit={handleSubmit} noValidate>
                    <div className="d-flex flex-wrap">
                        <div className="col-12 col-sm-6">
                            <SelectInput choices={params} source="name" label={translate('commons.name')} fullWidth defaultValue={currentParam?.name}/>
                        </div>
                        <div className="col-12 col-sm-6">
                            <ReferenceInput source="societeId" reference="Societes" label={translate('commons.societe')} fullWidth defaultValue={currentParam?.societeId}>
                                <SelectInput optionText="name" />
                            </ReferenceInput>
                        </div>
                        <FormDataConsumer>
                            {({ formData, ...rest }) => {
                                if (formData.name === ParameterType.LAST_VERSION) {
                                    return (
                                        <div className="d-flex flex-wrap w-100">
                                            <div className="col-12 col-sm-6">
                                                <TextInput source="latestVersion.link" label={translate('commons.link')} fullWidth defaultValue={currentParam?.value?.link}/>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <TextInput source="latestVersion.name" label={translate('commons.name')}  fullWidth defaultValue={currentParam?.value?.name}/>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <TextInput source="latestVersion.versionCode" label={translate('commons.versionCode')} fullWidth defaultValue={currentParam?.value?.versionCode}/>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <TextInput source="latestVersion.versionName" label={translate('commons.versionName')} fullWidth defaultValue={currentParam?.value?.versionName}/>
                                            </div>
                                            <div className="col-12">
                                                <TextInput source="latestVersion.changelogs" label={translate('commons.changelogs')} multiline fullWidth defaultValue={currentParam?.value?.changelogs}/>
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div className="col-12 cocl-sm-6">
                                            <TextInput source="value" label={translate('commons.value')} multiline fullWidth defaultValue={currentParam?.value}/>
                                        </div>
                                    )
                                }
                            }}
                        </FormDataConsumer>
                        <div className="col-12">
                            <TextInput source="description" label={translate('commons.description')} multiline fullWidth defaultValue={currentParam?.description}/>
                        </div>
                        <div className="col-12 mb-3">
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                disabled={loading}
                            >
                                {loading && (
                                    <CircularProgress
                                        size={25}
                                        thickness={2}
                                    />
                                )}
                                {translate('button.save')}
                            </Button>
                        </div>
                    </div>
                </form>
            )}
        />
    )
}
export const ParameterCreate = (props: any) => {
    const translate = useTranslate();
    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mt-3">
                {/* <Link to="/Parameters" className="text-decoration-none">
                    <ArrowBackIcon/> {translate('button.back')}
                </Link> */}
                <BackButton/>
                <h3>{translate('parameter.createParameter')}</h3>
                <b></b>
            </div>
            <Create {...props}>
                <CustomForm />
            </Create>
        </div>
    )
};

export const ParameterEdit = (props: any) => {
    const translate = useTranslate();
    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mt-3">
                {/* <Link to="/Parameters" className="text-decoration-none">
                    <ArrowBackIcon/> {translate('button.back')}
                </Link> */}
                <BackButton/>
                <h3>{translate('parameter.editParameter')}</h3>
                <b></b>
            </div>
            <Edit {...props}>
                <CustomForm />
            </Edit>
        </div>
    )
};
