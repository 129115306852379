import React, { useState } from 'react';
import {
    Create,
    Datagrid,
    DeleteButton,
    Edit,
    Filter,
    ImageField,
    ImageInput,
    List,
    SimpleForm,
    TextField,
    TextInput,
    EditButton, DateField, useTranslate, SelectInput, DateTimeInput, ReferenceInput
} from 'react-admin';

import { Link } from 'react-router-dom';
import { ShowImages } from '../components/show-images';
import { makeStyles } from '@material-ui/core/styles';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { AccountType, ContactType, Language } from '../react-admin-loopback/api/shared-constants';
import { LoopbackAPI } from '../react-admin-loopback/api/loopback-api';
import { API_URL } from '../variables/constant';
import ClearIcon from '@material-ui/core/SvgIcon/SvgIcon';
import BackButton from '../components/BackButton';


const SocieteFilter = (props: any) => (
    <Filter {...props}>
        <TextInput label="Nom" source="name" alwaysOn/>
    </Filter>
);

const useStyles = makeStyles({
    iconEyes: {
        color: 'rgb(204, 204, 0)',
        fontSize: 32
    }
});
const HaveEyeIconField = (props) => {
    const classes = useStyles();
    return (
        <Link to={`/Contacts?relationId=${props.record.id}&type=${ContactType.SOS}`}>
            <VisibilityOutlinedIcon className={classes.iconEyes}/>
        </Link>
    )
};

const ViewParameter = (props) => {
    const classes = useStyles();
    return (
        <Link to={`/Parameters?societeId=${props.record.id}`}>
            <VisibilityOutlinedIcon className={classes.iconEyes}/>
        </Link>
    )
};
const ViewModele = (props) => {
    const classes = useStyles();
    return (
        <Link to={`/Modeles?societeId=${props.record.id}`}>
            <VisibilityOutlinedIcon className={classes.iconEyes}/>
        </Link>
    )
};

export const SocieteList = (props: any) => {
    const translate = useTranslate();
    return  (
        <List {...props} filters={<SocieteFilter />}
              sort={{ field: "updatedAt", order: "DESC" }}
        >

            <Datagrid >
                <TextField source="id" />
                <TextField source="name" label={translate('commons.name')}/>
                <TextField source="language" label={translate('commons.language')}/>
                <ShowImages containerId="societes" imageUrl={props.logo} label={translate('commons.logo')}/>
                <HaveEyeIconField label={translate('commons.SOS')}/>
                <ViewParameter label={translate('commons.parameter')}/>
                <ViewModele label={translate('commons.modele')}/>
                <DateField source="createdAt" label={translate('commons.createdAt')}/>
                <DateField source="updatedAt" label={translate('commons.updatedAt')}/>
                <EditButton/>
                <DeleteButton undoable={false}/>
            </Datagrid>
        </List>
    )
};
const ImagesShow = (props) => {
    const [image, setImage] = useState(props.logo);
    const removeImage = async () => {
        const API = new LoopbackAPI();
        setImage(null);
        await API.societes.societePrototypePatchAttributes(props.id, {logo: undefined});
    };
    return (
        image?.length > 0 ? (
            <div className="d-flex align-items-center justify-content-center">
                <div className="wrap-image">
                    <img src={`${API_URL}/Containers/societes/download/${props?.logo}`} alt="" className="mr-2 custom-image"/>
                    <div className="ic-cancel" onClick={() => removeImage()}>
                        <ClearIcon/>
                    </div>
                </div>
            </div>
        ) : null
    );
};
const CustomForm = (props) => {
    const translate = useTranslate();
    const languages = Object.keys(Language).map(it => {
        return {id: it, name: Language[it]};
    });
    return (
        <div className="w-100">
            <div className="d-flex flex-wrap">
                {props?.isEdit && (
                    <div className="col-12">
                        <ImagesShow logo={props?.record?.logo} id={props?.record?.id}/>
                    </div>
                )}
                <div className="col-12">
                    <ImageInput source="pictures" label={translate('commons.uploadLogo')} accept="image/*" multiple={false} fullWidth>
                        <ImageField source="src" title="logo" />
                    </ImageInput>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="name" label={translate('commons.name')} fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <SelectInput choices={languages} source="language" label={translate('commons.language')} fullWidth/>
                </div>

            </div>
        </div>
    )
}
export const SocieteCreate = (props: any) => {
    const translate = useTranslate();
    const languages = Object.keys(Language).map(it => {
        return {id: it, name: Language[it]};
    });
    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mt-3">
                {/* <Link to="/Societes" className="text-decoration-none">
                    <ArrowBackIcon/> {translate('button.back')}
                </Link> */}
                <BackButton/>
                <h3>{translate('societe.createSociete')}</h3>
                <b></b>
            </div>
            <Create {...props} redirect="/Societes">
                <SimpleForm redirect="/Societes">
                    <CustomForm />
                </SimpleForm>
            </Create>
        </div>
    )
};

export const SocieteEdit = (props: any) => {
    const translate = useTranslate();
    const languages = Object.keys(Language).map(it => {
        return {id: it, name: Language[it]};
    });
    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mt-3">
                {/* <Link to="/Societes" className="text-decoration-none">
                    <ArrowBackIcon/> {translate('button.back')}
                </Link> */}
                <BackButton/>
                <h3>{translate('societe.editSociete')}</h3>
                <b></b>
            </div>
            <Edit {...props}>
                <SimpleForm>
                    <CustomForm isEdit={true}/>
                </SimpleForm>
            </Edit>
        </div>
    )
};
