import React from "react";
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
} from "react-admin";

export const getPositons = (props) => {
  return (
    <List {...props} exporter={false} sort={{ field: "id", order: "DESC" }}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="latitude" />
        <TextField source="longitude" />
        <DateField source="date" />
        <ReferenceField source="missionId" reference="Missions">
          <TextField source="id" />
        </ReferenceField>
        {/* <TextField source="missionId"/> */}
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
      </Datagrid>
    </List>
  );
};
