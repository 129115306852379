import React from 'react'
import { withRouter } from 'react-router'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import  { useTranslate } from "react-admin";
import { makeStyles } from '@material-ui/core/styles';

const BackButton = ({ history: { goBack }, children, ...props }) =>{ 
  const useStyles = makeStyles({
      backButton: {
        color: '#007bff !important'
      },
  });
  const classes = useStyles();
  return (
  <a style={{cursor:"pointer"}} className={classes.backButton + " text-decoration-none"} {...props} onClick={goBack}>
    <ArrowBackIcon /> {useTranslate()("button.back")}
  </a>
)}

export default withRouter(BackButton)