import { atom, selector } from "recoil";

const tabFilterInit = atom({
  key: "tabFilterInit",
  default: "mat",
});

const filterAssurance = selector({
  key: "filterAssurance",
  get: ({ get }) => {
    const text = get(tabFilterInit);

    return text.length;
  },
});

export { tabFilterInit, filterAssurance };
