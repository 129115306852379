import React from "react";
import {
  Create,
  Datagrid,
  DateField,
  DateInput,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  List,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useTranslate,
} from "react-admin";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { AccountType } from "../react-admin-loopback/api/shared-constants";
import BackButton from "../components/BackButton";

const MoteurFilter = (props: any) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <ReferenceInput
        source="accountId"
        perPage= { null }
        sort={{ field: "nom", order: "ASC" }}
        reference="Accounts"
        alwaysOn
        filter={{ type: AccountType.CONDUCTEUR }}
      >
        <SelectInput optionText="nom" />
        {/*<AutocompleteInput optionText="nom"/>*/}
      </ReferenceInput>
    </Filter>
  );
};

export const CustomForm = (props) => {
  const translate = useTranslate();
  return (
    <div className="w-100">
      <div className="d-flex flex-wrap">
        <div className="col-12 col-sm-6">
          <ReferenceInput
            source="accountId"
            reference="Accounts"
            perPage= { null }
            sort={{ field: "nom", order: "ASC" }}
            label={translate("commons.driver")}
            filter={{ type: AccountType.CONDUCTEUR }}
            fullWidth
          >
            <SelectInput optionText="nom" />
          </ReferenceInput>
        </div>
        <div className="col-12 col-sm-6">
          <TextInput
            source="parcMoteur"
            label={translate("commons.parcMoteur")}
            fullWidth
          />
        </div>
        <div className="col-12 col-sm-6">
          <TextInput
            source="matImMoteur"
            label={translate("commons.matImMoteur")}
            fullWidth
          />
        </div>
        <div className="col-12 col-sm-6">
          <TextInput
            source="kilometrage"
            label={translate("commons.kilometrage")}
            fullWidth
          />
        </div>
        <div className="col-12 col-sm-6">
          <TextInput
            source="consommation"
            label={translate("commons.consommation")}
            fullWidth
          />
        </div>
        <div className="col-12 col-sm-6">
          <TextInput
            source="vitesseMoy"
            label={translate("commons.vitesseMoy")}
            fullWidth
          />
        </div>
        <div className="col-12 col-sm-6">
          <TextInput
            source="tempsMoteur"
            label={translate("commons.tempsMoteur")}
            fullWidth
          />
        </div>
        <div className="col-12 col-sm-6">
          <TextInput
            source="tempsRoute"
            label={translate("commons.tempsRoute")}
            fullWidth
          />
        </div>
        <div className="col-12 col-sm-6">
          <TextInput
            source="tempsRalenti"
            label={translate("commons.tempsRalenti")}
            fullWidth
          />
        </div>
        <div className="col-12 col-sm-6">
          <TextInput
            source="tempsSpecif"
            label={translate("commons.tempsSpecif")}
            fullWidth
          />
        </div>
        <div className="col-12 col-sm-6">
          <NumberInput
            source="nivCarburant"
            label={translate("commons.nivCarburant")}
            fullWidth
          />
        </div>
        <div className="col-12 col-sm-6">
          <TextInput
            source="donneeSpecif"
            label={translate("commons.donneeSpecif")}
            fullWidth
          />
        </div>
        <div className="col-12 col-sm-6">
          <TextInput
            source="typeMoteur"
            label={translate("commons.typeMoteur")}
            fullWidth
          />
        </div>
        <div className="col-12 col-sm-6">
          <TextInput
            source="autonomie"
            label={translate("commons.autonomie")}
            fullWidth
          />
        </div>
      </div>
    </div>
  );
};
export const MoteurList = (props) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      filters={<MoteurFilter />}
      exporter={false}
      sort={{ field: "id", order: "DESC" }}
    >
      <Datagrid>
        <TextField source="id" />
        <ReferenceField source="accountId" reference="Accounts">
          <TextField source="nom" />
        </ReferenceField>
        <TextField
          source="parcMoteur"
          label={translate("commons.parcMoteur")}
        />
        <TextField
          source="matImMoteur"
          headerClassName="w-15 p-sm"
          cellClassName={"p-sm"}
          label={translate("commons.matImMoteur")}
        />
        <TextField
          source="kilometrage"
          label={translate("commons.kilometrage")}
        />
        <TextField
          source="consommation"
          label={translate("commons.consommation")}
        />
        <TextField
          source="vitesseMoy"
          label={translate("commons.vitesseMoy")}
        />
        <TextField
          source="tempsMoteur"
          label={translate("commons.tempsMoteur")}
        />
        <TextField
          source="tempsRoute"
          label={translate("commons.tempsRoute")}
        />
        <TextField
          source="tempsRalenti"
          label={translate("commons.tempsRalenti")}
        />
        <TextField
          source="tempsSpecif "
          label={translate("commons.tempsSpecif")}
        />
        <NumberField
          source="nivCarburant"
          label={translate("commons.nivCarburant")}
        />
        <TextField
          source="donneeSpecif"
          label={translate("commons.donneeSpecif")}
        />
        <TextField
          source="typeMoteur"
          label={translate("commons.typeMoteur")}
        />
        <TextField source="autonomie" label={translate("commons.autonomie")} />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export const MoteurCreate = (props: any) => {
  const translate = useTranslate();
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mt-3">
        {/* <Link to="/Moteurs" className="text-decoration-none">
          <ArrowBackIcon /> {translate("button.back")}
        </Link> */}
        <BackButton/>
        <h3>{translate("moteur.create")}</h3>
        <b></b>
      </div>
      <Create {...props}>
        <SimpleForm redirect="/Moteurs">
          <CustomForm />
        </SimpleForm>
      </Create>
    </div>
  );
};

export const MoteurEdit = (props: any) => {
  const translate = useTranslate();
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mt-3">
        {/* <Link to="/Moteurs" className="text-decoration-none">
          <ArrowBackIcon /> {translate("button.back")}
        </Link> */}
        <BackButton/>
        <h3>{translate("moteur.edit")}</h3>
        <b></b>
      </div>
      <Edit {...props}>
        <SimpleForm>
          <CustomForm />
        </SimpleForm>
      </Edit>
    </div>
  );
};
