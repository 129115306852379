import React from "react";
import {
    Create,
    Datagrid,
    DateField,
    DateInput,
    DeleteButton,
    Edit,
    EditButton,
    Filter,
    List,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
    useTranslate,
} from "react-admin";
import {AccountType} from "../react-admin-loopback/api/shared-constants";
import BackButton from "../components/BackButton";

const TempFilter = (props: any) => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <ReferenceInput
                source="accountId"
                perPage={null}
                reference="Accounts"
                sort={{field: "nom", order: "ASC"}}
                alwaysOn
                filter={{type: AccountType.CONDUCTEUR}}
            >
                <SelectInput optionText="nom"/>
                {/*<AutocompleteInput optionText="nom"/>*/}
            </ReferenceInput>
            {/*<SelectInput source="date"  alwaysOn/>*/}
        </Filter>
    );
};

export const CustomForm = (props) => {
    const translate = useTranslate();
    return (
        <div className="w-100">
            <div className="d-flex flex-wrap">
                <div className="col-12 col-sm-6">
                    <ReferenceInput
                        source="accountId"
                        reference="Accounts"
                        perPage={null}
                        sort={{field: "nom", order: "ASC"}}
                        label={translate("commons.driver")}
                        filter={{type: AccountType.CONDUCTEUR}}
                        fullWidth
                    >
                        <SelectInput optionText="nom"/>
                    </ReferenceInput>
                </div>
                <div className="col-12 col-sm-6">
                    <ReferenceInput source="missionId" reference="Missions" fullWidth>
                        <SelectInput optionText="ot"/>
                    </ReferenceInput>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="tempsEnCours" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="activiteEnCours" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="pbo" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="tempsDeService" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <DateInput source="priseDePosteJour" fullWidth/>
                    <p className="text-danger">Ce champ est obligatoire</p>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="amplitude" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="tempsServiceAuto" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="condContinueUser" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="condContinueAuto" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="condJourPast1" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="condJourPast2" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="condJourUser" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="condJourAuto" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="condSemUser" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="condSemAuto" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="condQuinzUser" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="condQuinzAuto" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="coupureUser" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="coupureObli" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="reposJourPast1" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="reposJourPast2" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="reposJourPast3" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="reposJourUser" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="reposJourObli" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="reposHebdoUser" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="reposHebdoObli" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="popupCoupure45" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="popupCoupure30" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="popupConduiteJour9" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="popupConduiteJour10" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="popupService" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="popupPriseDePoste" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="popupConduiteHebdo" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="popupConduiteQuinz" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="popupCoupure" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="popupReposJour" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="popupReposHebdo" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="popupCoup4h" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="popupCoup4h30" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="popupCoup4hf" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="popupCoup4h30f" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="travailmois" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="travailhebdo" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="travailjour" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="servicemois" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="servicehebdo" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="condmois" fullWidth/>
                </div>
                <div className="col-12 col-sm-6">
                    <DateInput source="actualisation" fullWidth/>
                    <p className="text-danger">Ce champ est obligatoire</p>
                </div>
            </div>
        </div>
    );
};
export const TempList = (props) => {
    const translate = useTranslate();
    return (
        <List {...props} filters={<TempFilter/>} exporter={false}>
            <Datagrid>
                <TextField source="id"/>
                <ReferenceField source="accountId" reference="Accounts">
                    <TextField source="nom" label={translate("commons.driver")}/>
                </ReferenceField>
                <ReferenceField
                    source="missionId"
                    reference="Missions"
                    label={translate("commons.mission")}
                >
                    <TextField source="ot"/>
                </ReferenceField>
                <TextField source="tempsEnCours"/>
                <TextField source="activiteEnCours"/>
                <TextField source="pbo"/>
                <TextField source="tempsDeService"/>
                <DateField source="priseDePosteJour"
                           showTime/>
                <TextField source="amplitude"/>
                <TextField source="tempsServiceAuto"/>
                <TextField source="condContinueUser"/>
                <TextField source="condContinueAuto"/>
                <TextField source="condJourPast1"/>
                <TextField source="condJourPast2"/>
                <TextField source="condJourUser"/>
                <TextField source="condJourAuto"/>
                <TextField source="condSemUser"/>
                <TextField source="condSemAuto"/>
                <TextField source="condQuinzUser"/>
                <TextField source="condQuinzAuto"/>
                <TextField source="coupureUser"/>
                <TextField source="coupureObli"/>
                <TextField source="reposJourPast1"/>
                <TextField source="reposJourPast2"/>
                <TextField source="reposJourPast3"/>
                <TextField source="reposJourUser"/>
                <TextField source="reposJourObli"/>
                <TextField source="reposHebdoUser"/>
                <TextField source="reposHebdoObli"/>
                <TextField source="popupCoupure45"/>
                <TextField source="popupCoupure30"/>
                <TextField source="popupConduiteJour9"/>
                <TextField source="popupConduiteJour10"/>
                <TextField source="popupService"/>
                <TextField source="popupPriseDePoste"/>
                <TextField source="popupConduiteHebdo"/>
                <TextField source="popupConduiteQuinz"/>
                <TextField source="popupCoupure"/>
                <TextField source="popupReposJour"/>
                <TextField source="popupReposHebdo"/>
                <TextField source="popupCoup4h"/>
                <TextField source="popupCoup4h30"/>
                <TextField source="popupCoup4hf"/>
                <TextField source="popupCoup4h30f"/>
                <TextField source="travailmois"/>
                <TextField source="travailhebdo"/>
                <TextField source="travailjour"/>
                <TextField source="servicemois"/>
                <TextField source="servicehebdo"/>
                <TextField source="condmois"/>
                <DateField source="actualisation" showTime/>
                <EditButton/>
                <DeleteButton/>
            </Datagrid>
        </List>
    );
};

export const TempEdit = (props: any) => {
    const translate = useTranslate();
    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mt-3">
                {/* <Link to="/RHs" className="text-decoration-none">
          <ArrowBackIcon /> {translate("button.back")}
        </Link> */}
                <BackButton/>
                <h3>{"Temp Edit"}</h3>
                <b></b>
            </div>
            <Edit {...props}>
                <SimpleForm>
                    <CustomForm/>
                </SimpleForm>
            </Edit>
        </div>
    );
};
export const TempCreate = (props: any) => {
    const translate = useTranslate();
    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mt-3">
                {/* <Link to="/RHs" className="text-decoration-none">
          <ArrowBackIcon /> {translate("button.back")}
        </Link> */}
                <BackButton/>
                <h3>{"Temp Create"}</h3>
                <b></b>
            </div>
            <Create {...props}>
                <SimpleForm redirect="/TempServices">
                    <CustomForm/>
                </SimpleForm>
            </Create>
        </div>
    );
};
