import React, {useState} from "react";
import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    DateField,
    FunctionField,
    DateInput,
    DeleteButton,
    Edit,
    EditButton,
    Filter,
    ImageField,
    ImageInput,
    List,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
    useTranslate,
} from "react-admin";
import {Tab, Tabs} from "@material-ui/core";
import {AccountType} from "../react-admin-loopback/api/shared-constants";
import {Link} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import {LoopbackAPI} from "../react-admin-loopback/api/loopback-api";
import {API_URL} from "../variables/constant";
import ClearIcon from "@material-ui/icons/Clear";
import {useRecoilState} from "recoil";
import {tabFilterInit,} from "../recoil-config/atom/assurances-atom";
import BackButton from "../components/BackButton";
import moment from "moment";

const AssuranceFilter = (props: any) => (
    <Filter {...props}>
        <ReferenceInput
            source="accountId"
            reference="Accounts"
            perPage={null}
            alwaysOn
            sort={{field: "nom", order: "ASC"}}
        >
            <SelectInput optionText="nom"/>
        </ReferenceInput>
    </Filter>
);

const HaveEyeIconField = (props) => {
    const useStyles = makeStyles({
        iconEyes: {
            color: "rgb(204, 204, 0)",
            fontSize: 32,
        },
    });
    const classes = useStyles();
    return props?.record?.images?.length > 0 ? (
        <VisibilityOutlinedIcon className={classes.iconEyes}/>
    ) : (
        <span>-</span>
    );
};

const ImagesShow = (props) => {
    const [listImages, setListImage] = useState(props.images);
    const removeImage = async (index) => {
        const API = new LoopbackAPI();
        listImages.splice(index, 1);
        setListImage([...listImages]);
        // await API.assurances.assurancePrototypePatchAttributes(props.id, {images: [...listImages]});
    };
    return listImages?.length > 0 ? (
        <div className="d-flex wrap-list-image overflow-auto">
            {listImages.map((item, index) => {
                return (
                    <div className="wrap-image" key={"image" + index}>
                        <img
                            src={`${API_URL}/Containers/photos/download/${item}`}
                            alt=""
                            className="mr-2 wrap-image"
                        />
                        <div className="ic-cancel" onClick={() => removeImage(index)}>
                            <ClearIcon/>
                        </div>
                    </div>
                );
            })}
        </div>
    ) : null;
};

const ViewFormulaire = (props) => {
    const useStyles = makeStyles({
        iconEyes: {
            color: "rgb(204, 204, 0)",
            fontSize: 32,
        },
    });
    const classes = useStyles();
    return (
        <Link
            to={`/formulaire?assuranceId=${props.record.id}`}
            onClick={(e) => e.stopPropagation()}
            target="_blank"
        >
            <VisibilityOutlinedIcon className={classes.iconEyes}/>
        </Link>
    );
};

const CustomForm = (props) => {
    const translate = useTranslate();
    const [tabFilter] = useRecoilState(tabFilterInit);

    return (
        <div className="d-flex flex-wrap">
            {props?.isEdit && (
                <div className="col-12">
                    <ImagesShow images={props?.record?.images} id={props?.record?.id}/>
                </div>
            )}
            <div className="col-12">
                <ImageInput
                    source="pictures"
                    label={translate("commons.uploadImage")}
                    accept="image/*"
                    multiple={true}
                >
                    <ImageField source="src" title="image"/>
                </ImageInput>
            </div>
            <div className="col-4">
                <ReferenceInput
                    source="accountId"
                    reference="Accounts"
                    perPage={null}
                    filter={{type: AccountType.CONDUCTEUR}}
                    sort={{field: "nom", order: "ASC"}}
                    label={translate("commons.driver")}
                    fullWidth
                >
                    <SelectInput optionText="nom"/>
                </ReferenceInput>
            </div>


            {
                (tabFilter === "mat") ? (
                    <div className="d-flex flex-wrap">
                        <div className="col-4">
                            <TextInput multiline source="matImMoteur" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matImTracte" fullWidth/>
                        </div>
                        <div className="col-4">
                            <DateInput multiline source="matDateSin" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matLocalite" fullWidth/>
                        </div>
                        <div className="col-4">
                            <SelectInput
                                fullWidth
                                source="matTypeLieu"
                                choices={[
                                    {
                                        id: "Ville",
                                        name: "Ville",
                                    },
                                    {id: "Autoroute", name: "Autoroute"},
                                    {id: "Nationale", name: "Nationale"},
                                    {id: "Parking", name: "Parking"},
                                    {id: "Autre", name: "Autre"},
                                ]}
                            />
                        </div>
                        <div className="col-4">
                            <BooleanInput multiline source="matConstat" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matCause" fullWidth/>
                        </div>
                        <div className="col-4">
                            <BooleanInput multiline source="matDomCorp" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matDomMat" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matLoc1" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matLoc1Im1" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matLoc1Im2" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matLoc1Im3" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matLoc2" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matLoc2Im1" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matLoc2Im2" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matLoc2Im3" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matLoc3" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matLoc3Im1" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matLoc3Im2" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matLoc3Im3" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matLoc4" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matLoc4Im1" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matLoc4Im2" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matLoc4Im3" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matLoc5" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matLoc5Im1" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matLoc5Im2" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matLoc5Im3" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matLoc6" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matLoc6Im1" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matLoc6Im2" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matLoc6Im3" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matLoc7" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matLoc7Im1" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matLoc7Im2" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matLoc7Im3" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matLoc8" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matLoc8Im1" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matLoc8Im2" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matLoc8Im3" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matLoc9" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matLoc9Im1" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matLoc9Im2" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matLoc9Im3" fullWidth/>
                        </div>
                        <div className="col-4">
                            <SelectInput
                                fullWidth
                                source="matEtat"
                                choices={[
                                    {
                                        id: "Attente validation exploitant",
                                        name: "Attente validation exploitant",
                                    },
                                    {id: "A compléter", name: "A compléter"},
                                    {id: "Validée", name: "Validée"},
                                ]}
                            />
                        </div>
                        {/*<div className="col-4">*/}
                        {/*    <DateInput multiline source="matDateTransExp" fullWidth/>*/}
                        {/*</div>*/}
                        <div className="col-4">
                            <DateInput multiline source="matHeureTransExp" fullWidth/>
                        </div>
                        <div className="col-4">
                            <DateInput multiline source="matValidationExp" fullWidth/>
                        </div>
                        <div className="col-4">
                            <DateInput multiline source="matDateTransAss" fullWidth/>
                        </div>
                        {/*<div className="col-4">*/}
                        {/*  <DateInput multiline source="matHeureTransAss" fullWidth />*/}
                        {/*</div>*/}
                        <div className="col-4">
                            <TextInput multiline source="matDateSaisie" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="matConstatIm" fullWidth/>
                        </div>
                    </div>
                ) : (
                    <div className="d-flex flex-wrap">
                        <div className="col-4">
                            <TextInput multiline source="marOt" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="marIm2" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="marIm3" fullWidth/>
                        </div>
                        <div className="col-4">
                            <SelectInput
                                fullWidth
                                source="marSituation"
                                choices={[
                                    {id: "Chargement", name: "Chargement"},
                                    {id: "Circulation", name: "Circulation"},
                                    {id: "Déchargement", name: "Déchargement"},
                                ]}
                            />
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="marCmrNum" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="marCmrIm" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="marDommages" fullWidth/>
                        </div>
                        <div className="col-4">
                            <TextInput multiline source="marLieu" fullWidth/>
                        </div>
                        <div className="col-4">
                            <SelectInput
                                fullWidth
                                source="marCause"
                                choices={[
                                    {
                                        id: "Conditions climatiques",
                                        name: "Conditions climatiques",
                                    },
                                    {
                                        id: "Sanglage ou arrimage défaillant",
                                        name: "Sanglage ou arrimage défaillant",
                                    },
                                    {
                                        id: "Comportement de conduite",
                                        name: "Comportement de conduite",
                                    },
                                    {
                                        id: "Conditionnement des marchandises",
                                        name: "Conditionnement des marchandises",
                                    },
                                    {id: "Expéditeur", name: "Expéditeur"},
                                    {id: "Destinataire", name: "Destinataire"},
                                    {id: "Autre", name: "Autre"},
                                ]}
                            />
                        </div>
                        {/*<div className="col-4">*/}
                        {/*  <DateInput multiline source="marDate" fullWidth />*/}
                        {/*</div>*/}

                        <div className="col-4">
                            <SelectInput
                                fullWidth
                                source="marEtat"
                                choices={[
                                    {
                                        id: "Attente validation exploitant",
                                        name: "Attente validation exploitant",
                                    },
                                    {id: "A compléter", name: "A compléter"},
                                    {id: "Validée", name: "Validée"},
                                ]}
                            />
                        </div>
                        <div className="col-4">
                            <DateInput multiline source="marValidationExp" fullWidth/>
                        </div>
                        <div className="col-4">
                            <DateInput multiline source="marDateTransExp" fullWidth/>
                        </div>
                        {/*<div className="col-4">*/}
                        {/*  <DateInput multiline source="marHeureTransExp" fullWidth />*/}
                        {/*</div>*/}
                        <div className="col-4">
                            <DateInput multiline source="marDateTransAss" fullWidth/>
                        </div>
                    </div>
                )
            }
            <div className="col-4">
                <TextInput multiline source="assurance" fullWidth/>
            </div>
            <div className="col-4">
                <TextInput multiline source="nomPrenom" fullWidth/>
            </div>
            <div className="col-4">
                <TextInput multiline source="adresseUser" fullWidth/>
            </div>
            <div className="col-4">
                <TextInput multiline source="visuelMot" fullWidth/>
            </div>
            <div className="col-4">
                <TextInput multiline source="visuelTract" fullWidth/>
            </div>
            <div className="col-4">
                <DateInput multiline source="lieuSaisie" fullWidth/>
            </div>
            <div className="col-4">
                <BooleanInput multiline source="controleSt" fullWidth/>
            </div>
        </div>
    );
};

export const AssuranceList = (props) => {
    const dateFormatter = v => {
        if (!v) return;
        const date = v instanceof Date ? v : new Date(v);
        return moment(date).format('DD/MM/yyyy HH:mm');
    };

    const translate = useTranslate();
    const [tabFilter, setTabFilter] = useRecoilState(tabFilterInit);
    return (
        <>
            <Tabs
                variant="fullWidth"
                value={tabFilter}
                indicatorColor="primary"
                onChange={(event: React.ChangeEvent<{}>, value: any) =>
                    setTabFilter(value)
                }
                centered={false}
            >
                <Tab label="Mat" key="mat" value="mat"/>
                <Tab label="Mar" key="mar" value="mar"/>
            </Tabs>
            {(tabFilter === "mat") ? (
                <List
                    {...props}
                    filters={<AssuranceFilter/>}
                    sort={{field: "updatedAt", order: "DESC"}}
                    filter={{marOt: null}}
                >
                    <Datagrid rowClick="edit">
                        <TextField source="id"/>
                        <ReferenceField
                            source="accountId"
                            reference="Accounts"
                            sort={{field: "nom", order: "ASC"}}
                        >
                            <TextField source="nom" label={translate("commons.driver")}/>
                        </ReferenceField>
                        <ViewFormulaire label={translate("commons.viewForm")}/>
                        {/* <TextField source="marOt" />
            <TextField source="marIm2" />
            <TextField source="marIm3" /> */}
                        <TextField source="assurance"/>
                        {/* <TextField source="marSituation" />
            <TextField source="marCmrNum" />
            <TextField source="marCmrIm" />
            <TextField source="marDommages" />
            <TextField source="marLieu" />
            <TextField source="marCause" />
            <TextField source="marIm1" /> */}
                        <TextField source="matImMoteur"/>
                        <TextField source="matImTracte"/>
                        <DateField source="matDateSin"/>
                        <TextField source="matLocalite"/>
                        <TextField source="matTypeLieu"/>
                        <BooleanField source="matConstat"/>
                        <TextField source="matCause"/>
                        <BooleanField source="matDomCorp"/>
                        <TextField source="matDomMat"/>
                        <TextField source="matLoc1"/>
                        <TextField source="matLoc1Im1"/>
                        <TextField source="matLoc1Im2"/>
                        <TextField source="matLoc1Im3"/>
                        <TextField source="matLoc2"/>
                        <TextField source="matLoc2Im1"/>
                        <TextField source="matLoc2Im2"/>
                        <TextField source="matLoc2Im3"/>
                        <TextField source="matLoc3"/>
                        <TextField source="matLoc3Im1"/>
                        <TextField source="matLoc3Im2"/>
                        <TextField source="matLoc3Im3"/>
                        <TextField source="matLoc4"/>
                        <TextField source="matLoc4Im1"/>
                        <TextField source="matLoc4Im2"/>
                        <TextField source="matLoc4Im3"/>
                        <TextField source="matLoc5"/>
                        <TextField source="matLoc5Im1"/>
                        <TextField source="matLoc5Im2"/>
                        <TextField source="matLoc5Im3"/>
                        <TextField source="matLoc6"/>
                        <TextField source="matLoc6Im1"/>
                        <TextField source="matLoc6Im2"/>
                        <TextField source="matLoc6Im3"/>
                        <TextField source="matLoc7"/>
                        <TextField source="matLoc7Im1"/>
                        <TextField source="matLoc7Im2"/>
                        <TextField source="matLoc7Im3"/>
                        <TextField source="matLoc8"/>
                        <TextField source="matLoc8Im1"/>
                        <TextField source="matLoc8Im2"/>
                        <TextField source="matLoc8Im3"/>
                        <TextField source="matLoc9"/>
                        <TextField source="matLoc9Im1"/>
                        <TextField source="matLoc9Im2"/>
                        <TextField source="matLoc9Im3"/>
                        <TextField source="nomPrenom"/>
                        <TextField source="adresseUser"/>
                        {/* <TextField source="marDate" /> */}
                        {/* <TextField source="marEtat" /> */}
                        <TextField source="matEtat"/>
                        {/* <TextField source="marValidationExp" /> */}
                        <TextField source="matValidationExp"/>
                        {/* <TextField source="marDateTransExp" /> */}
                        {/* <TextField source="marHeureTransExp" /> */}
                        {/*<FunctionField*/}
                        {/*    label="Mat Date Trans Exp"*/}
                        {/*    render={record => dateFormatter(record.matDateTransExp)}*/}
                        {/*/>;*/}
                        {/*<TextField source="matDateTransExp"/>*/}
                        <TextField source="matHeureTransExp"/>
                        {/* <TextField source="marDateTransAss" /> */}
                        <FunctionField
                            label="Mat date trans ass"
                            render={record => dateFormatter(record.matDateTransAss)}
                        />;
                        {/*<TextField source="matHeureTransAss"/>*/}
                        <TextField source="visuelMot"/>
                        <TextField source="visuelTract"/>
                        <DateField source="matDateSaisie"/>
                        <TextField source="matConstatIm"/>
                        <TextField source="lieuSaisie"/>
                        <BooleanField source="controleSt"/>
                        <TextField source="imagelibre"/>
                        <EditButton/>
                        <DeleteButton undoable={false}/>
                    </Datagrid>
                </List>
            ) : (
                <List
                    {...props}
                    filters={<AssuranceFilter/>}
                    sort={{field: "updatedAt", order: "DESC"}}
                    filter={{marOt: {neq: null}}}
                >
                    <Datagrid rowClick="edit">
                        <TextField source="id"/>
                        <ReferenceField
                            source="accountId"
                            reference="Accounts"
                            sort={{field: "nom", order: "ASC"}}
                        >
                            <TextField source="nom" label={translate("commons.driver")}/>
                        </ReferenceField>
                        <ViewFormulaire label={translate("commons.viewForm")}/>
                        <TextField source="marOt"/>
                        <TextField source="marIm2"/>
                        <TextField source="marIm3"/>
                        <TextField source="assurance"/>
                        <TextField source="marSituation"/>
                        <TextField source="marCmrNum"/>
                        <TextField source="marCmrIm"/>
                        <TextField source="marDommages"/>
                        <TextField source="marLieu"/>
                        <TextField source="marCause"/>
                        <TextField source="marIm1"/>
                        {/* <TextField source="matImMoteur" />
            <TextField source="matImTracte" />
            <DateField source="matDateSin" />
            <TextField source="matLocalite" />
            <TextField source="matTypeLieu" />
            <BooleanField source="matConstat" />
            <TextField source="matCause" />
            <BooleanField source="matDomCorp" />
            <TextField source="matDomMat" />
            <TextField source="matLoc1" />
            <TextField source="matLoc1Im1" />
            <TextField source="matLoc1Im2" />
            <TextField source="matLoc1Im3" />
            <TextField source="matLoc2" />
            <TextField source="matLoc2Im1" />
            <TextField source="matLoc2Im2" />
            <TextField source="matLoc2Im3" />
            <TextField source="matLoc3" />
            <TextField source="matLoc3Im1" />
            <TextField source="matLoc3Im2" />
            <TextField source="matLoc3Im3" />
            <TextField source="matLoc4" />
            <TextField source="matLoc4Im1" />
            <TextField source="matLoc4Im2" />
            <TextField source="matLoc4Im3" />
            <TextField source="matLoc5" />
            <TextField source="matLoc5Im1" />
            <TextField source="matLoc5Im2" />
            <TextField source="matLoc5Im3" />
            <TextField source="matLoc6" />
            <TextField source="matLoc6Im1" />
            <TextField source="matLoc6Im2" />
            <TextField source="matLoc6Im3" />
            <TextField source="matLoc7" />
            <TextField source="matLoc7Im1" />
            <TextField source="matLoc7Im2" />
            <TextField source="matLoc7Im3" />
            <TextField source="matLoc8" />
            <TextField source="matLoc8Im1" />
            <TextField source="matLoc8Im2" />
            <TextField source="matLoc8Im3" />
            <TextField source="matLoc9" />
            <TextField source="matLoc9Im1" />
            <TextField source="matLoc9Im2" />
            <TextField source="matLoc9Im3" /> */}
                        <TextField source="nomPrenom"/>
                        <TextField source="adresseUser"/>
                        {/*<TextField source="marDate" />*/}
                        <TextField source="marEtat"/>
                        {/* <TextField source="matEtat" /> */}
                        <TextField source="marValidationExp"/>
                        {/* <TextField source="matValidationExp" /> */}
                        <FunctionField
                            label="Mar Date Trans Exp"
                            render={record => dateFormatter(record.marDateTransExp)}
                        />;
                        {/*<TextField source="marDateTransExp"/>*/}
                        {/*<TextField source="marHeureTransExp" />*/}
                        {/* <TextField source="matDateTransExp" />
            <TextField source="matHeureTransExp" /> */}
                        <TextField source="marDateTransAss"/>
                        {/* <TextField source="matHeureTransAss" /> */}
                        <TextField source="visuelMot"/>
                        <TextField source="visuelTract"/>
                        {/* <DateField source="matDateSaisie" />
            <TextField source="matConstatIm" /> */}
                        <TextField source="lieuSaisie"/>
                        <BooleanField source="controleSt"/>
                        <EditButton/>
                        <DeleteButton undoable={false}/>
                    </Datagrid>
                </List>
            )}
        </>
    );
};

export const AssuranceCreate = (props: any) => {
    const translate = useTranslate();
    const transform = (data) => ({
        ...data,
        marDate: data.marDate == "" ? null : data.marDate,
        matDate: data.matDate == "" ? null : data.matDate,
        matDateSin: data.matDateSin == "" ? null : data.matDateSin,
        marDateSin: data.marDateSin == "" ? null : data.marDateSin,
        marDateTransExp: data.marDateTransExp == "" ? null : data.marDateTransExp,
        // matDateTransExp: data.matDateTransExp == "" ? null : data.matDateTransExp,
        matDateTransAss: data.matDateTransAss == "" ? null : data.matDateTransAss,
        marDateTransAss: data.marDateTransAss == "" ? null : data.marDateTransAss,
    });
    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mt-3">
                {/* <Link to="/Assurances" className="text-decoration-none">
          <ArrowBackIcon /> {translate("button.back")}
        </Link> */}
                <BackButton/>
                <h3>{translate("assurance.createAssurance")}</h3>
                <b></b>
            </div>
            <Create {...props} transform={transform}>
                <SimpleForm redirect="/Assurances">
                    <CustomForm/>
                </SimpleForm>
            </Create>
        </div>
    );
};

export const AssuranceEdit = (props: any) => {
    const translate = useTranslate();
    const transform = (data) => ({
        ...data,
        marDate: data.marDate == "" ? null : data.marDate,
        matDate: data.matDate == "" ? null : data.matDate,
        matDateSin: data.matDateSin == "" ? null : data.matDateSin,
        marDateSin: data.marDateSin == "" ? null : data.marDateSin,
        marDateTransExp: data.marDateTransExp == "" ? null : data.marDateTransExp,
        // matDateTransExp: data.matDateTransExp == "" ? null : data.matDateTransExp,
        matDateTransAss: data.matDateTransAss == "" ? null : data.matDateTransAss,
        marDateTransAss: data.marDateTransAss == "" ? null : data.marDateTransAss,
    });
    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mt-3">
                {/* <Link to="/Assurances" className="text-decoration-none">
          <ArrowBackIcon /> {translate("button.back")}
        </Link> */}
                <BackButton/>
                <h3>{translate("assurance.editAssurance")}</h3>
                <b></b>
            </div>
            <Edit {...props} transform={transform}>
                <SimpleForm>
                    <CustomForm isEdit={true}/>
                </SimpleForm>
            </Edit>
        </div>
    );
};
