import Button from "@material-ui/core/Button";
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import React from "react";
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
const ModalValidatePassword = ({ show, handleClose }) => {
  const history = useHistory();
  return (
    <>
      <Modal show={show} >
        <Modal.Header >
          <Modal.Title>Mot de passe faible</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Mot de passe faible. Veuillez changer votre mot de passe.
        </Modal.Body>
        <Modal.Footer>
          <Button startIcon={<CheckCircleOutlineIcon />} color="secondary" onClick={handleClose}>
            Accepter
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
};

export default ModalValidatePassword;
