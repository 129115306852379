// in src/Menu.js
import AlbumIcon from '@material-ui/icons/Album';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import ControlCameraIcon from '@material-ui/icons/ControlCamera';
import EventNoteIcon from '@material-ui/icons/EventNote';
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import NoteIcon from '@material-ui/icons/Note';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PersonIcon from '@material-ui/icons/Person';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import SettingsIcon from '@material-ui/icons/Settings';
import MapIcon from '@material-ui/icons/Map';
import HistoryIcon from '@material-ui/icons/History';
import TheatersIcon from '@material-ui/icons/Theaters';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import * as React from 'react';
import { useState } from 'react';
import { getResources, MenuItemLink, useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { storage } from '../react-admin-loopback';
import { keyIV, secretKey } from '../variables/secretKey';
import * as CryptoJS from "crypto-js";
import { API_URL, TABLEAU_DE_BORD_URL } from '../variables/constant';

interface Props {
    dense: boolean;
    logout: () => void;
    onMenuClick: () => void;
}

const Menu = ({ onMenuClick, dense, logout }: any) => {
    const translate = useTranslate();
    const open = true;
    const resources: any[] = useSelector(getResources);
    const [state, setState] = useState({
        menuCatalog: false,
        menuSales: false,
        menuCustomers: false
    });
    const handleToggle = (menu: any) => {
        setState((state) => ({ ...state }));
    };
    const [openCollapse, setopenCollapse] = React.useState([false, false]);

    const handleClick = (index: number) => {
        openCollapse[index] = !openCollapse[index];
        setopenCollapse([...openCollapse]);
    };

    const token = storage.load('lbtoken')

    var encrypted = CryptoJS.AES.encrypt(
        token.id, 
        CryptoJS.enc.Utf8.parse(secretKey), 
        {
            iv: CryptoJS.enc.Utf8.parse(keyIV),  
            padding: CryptoJS.pad.Pkcs7,  
            mode: CryptoJS.mode.CBC
        }
    );


    return (
        <div className="qlmenu">
            <MenuItemLink
                className="ql-item-menu"
                to="/Accounts"
                primaryText={translate('menu.account')}
                leftIcon={<PersonIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            {/*<MenuItemLink*/}
                {/*className="ql-item-menu"*/}
                {/*to="/Societes"*/}
                {/*primaryText={translate('menu.company')}*/}
                {/*leftIcon={<BusinessIcon/>}*/}
                {/*onClick={onMenuClick}*/}
                {/*sidebarIsOpen={open}*/}
            {/*/>*/}
            {/*<MenuItemLink*/}
                {/*className="ql-item-menu"*/}
                {/*to="/Services"*/}
                {/*primaryText={translate('menu.service')}*/}
                {/*leftIcon={<LocationCityIcon/>}*/}
                {/*onClick={onMenuClick}*/}
                {/*sidebarIsOpen={open}*/}
            {/*/>*/}
            <MenuItemLink
                className="ql-item-menu"
                to="/Assurances"
                primaryText={translate('menu.assurances')}
                leftIcon={<BeenhereIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            {/*<MenuItemLink*/}
                {/*className="ql-item-menu"*/}
                {/*to="/Contacts"*/}
                {/*primaryText={translate('menu.contacts')}*/}
                {/*leftIcon={<PermContactCalendarIcon/>}*/}
                {/*onClick={onMenuClick}*/}
                {/*sidebarIsOpen={open}*/}
            {/*/>*/}
            {/*<MenuItemLink*/}
                {/*className="ql-item-menu"*/}
                {/*to="/Modeles"*/}
                {/*primaryText={translate('menu.model')}*/}
                {/*leftIcon={<PermContactCalendarIcon/>}*/}
                {/*onClick={onMenuClick}*/}
                {/*sidebarIsOpen={open}*/}
            {/*/>*/}
            {/*<MenuItemLink*/}
                {/*className="ql-item-menu"*/}
                {/*to="/Photos"*/}
                {/*primaryText={translate('menu.photos')}*/}
                {/*leftIcon={<PhotoIcon/>}*/}
                {/*onClick={onMenuClick}*/}
                {/*sidebarIsOpen={open}*/}
            {/*/>*/}
            {/*<MenuItemLink*/}
                {/*className="ql-item-menu"*/}
                {/*to="/Media"*/}
                {/*primaryText={translate('menu.medias')}*/}
                {/*leftIcon={<PermMediaIcon/>}*/}
                {/*onClick={onMenuClick}*/}
                {/*sidebarIsOpen={open}*/}
            {/*/>*/}
            {/*<ListItem button onClick={() => handleClick(0)} className="ql-item-menu">*/}
                {/*<ListItemIcon>*/}
                    {/*<LocalShippingIcon />*/}
                {/*</ListItemIcon>*/}
                {/*<ListItemText primary={translate("menu.mission")} />*/}
                {/*{openCollapse[0] ? <ExpandLess /> : <ExpandMore />}*/}
            {/*</ListItem>*/}
            {/*<Collapse in={openCollapse[0]} timeout="auto" unmountOnExit>*/}
                {/*<List component="div" className="">*/}
                    <MenuItemLink
                        className="ql-item-menu"
                        to="/Missions"
                        primaryText={translate('menu.mission')}
                        leftIcon={<LocalShippingIcon/>}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                    />
                    <MenuItemLink
                        className="ql-item-menu"
                        to="/EtapeOfDriver"
                        primaryText={translate('menu.etape')}
                        leftIcon={<PlaylistAddCheckIcon/>}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                    />
                    {/*<MenuItemLink*/}
                        {/*className="ql-item-menu"*/}
                        {/*to="/Horaires"*/}
                        {/*primaryText={translate('menu.horaises')}*/}
                        {/*leftIcon={<QueryBuilderIcon/>}*/}
                        {/*onClick={onMenuClick}*/}
                        {/*sidebarIsOpen={open}*/}
                    {/*/>*/}
                    <MenuItemLink
                        className="ql-item-menu"
                        to="/Reserves"
                        primaryText={translate('menu.reseve')}
                        leftIcon={<NoteIcon/>}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                    />
                    <MenuItemLink
                        className="ql-item-menu"
                        to="/CMRs"
                        primaryText={translate('menu.CMR')}
                        leftIcon={<EventNoteIcon/>}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                    />
            <MenuItemLink
                className="ql-item-menu"
                to="/Moteurs"
                primaryText={translate('menu.moteur')}
                leftIcon={<AlbumIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                className="ql-item-menu"
                to="/Tractes"
                primaryText={translate('menu.tractes')}
                leftIcon={<ControlCameraIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
                    {/*<MenuItemLink*/}
                        {/*className="ql-item-menu"*/}
                        {/*to="/DNs"*/}
                        {/*primaryText={translate('menu.DN')}*/}
                        {/*leftIcon={<EventNoteIcon/>}*/}
                        {/*onClick={onMenuClick}*/}
                        {/*sidebarIsOpen={open}*/}
                    {/*/>*/}
                {/*</List>*/}
            {/*</Collapse>*/}
            <MenuItemLink
                className="ql-item-menu"
                to="/RHs"
                primaryText={'RHS'}
                leftIcon={<TheatersIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                className="ql-item-menu"
                to="/InAppNotifications"
                primaryText={translate('menu.appNotifications')}
                leftIcon={<NotificationsIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                className="ql-item-menu"
                to="/Verifications"
                primaryText={translate('menu.verify')}
                leftIcon={<VerifiedUserIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                className="ql-item-menu"
                to="/TempServices"
                primaryText={translate('menu.temp')}
                leftIcon={<SettingsIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                className="ql-item-menu"
                to="/PleinCarburants"
                primaryText={translate('menu.pleinCarburants')}
                leftIcon={<LocalGasStationIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                className="ql-item-menu"
                to="/Positions"
                primaryText={translate('menu.position')}
                leftIcon={<MapIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                className="ql-item-menu"
                to="/Historyvieprivees"
                primaryText={translate('menu.historyvieprivee')}
                leftIcon={<HistoryIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                className="ql-item-menu"
                to="/Parametres"
                primaryText={translate('menu.guide')}
                leftIcon={<SettingsIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                className="ql-item-menu"
                to="/GuideConducteur1"
                primaryText={translate('menu.guideConducteur1')}
                leftIcon={<SettingsIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                className="ql-item-menu"
                to="/GuideConducteur2"
                primaryText={translate('menu.guideConducteur2')}
                leftIcon={<SettingsIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                className="ql-item-menu"
                to="/GuideConducteur3"
                primaryText={translate('menu.guideConducteur3')}
                leftIcon={<SettingsIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                className="ql-item-menu"
                to="/AppVersions"
                primaryText={translate('menu.appversion')}
                leftIcon={<SettingsIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                className="ql-item-menu"
                to="/Installations"
                primaryText={translate('menu.installation')}
                leftIcon={<SettingsIcon/>}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            <MenuItemLink
                className="ql-item-menu"
                onClick={() => {
                    window.open(`${TABLEAU_DE_BORD_URL}/redirect?encryptedToken=${encrypted}`)
                }}
                to="/"
                primaryText={'Tableau de bord'}
                leftIcon={<SettingsIcon/>}
                sidebarIsOpen={open}
            />
        </div>
    );
};

export default withRouter(Menu as any);
