import React from "react";
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  DateInput,
  DateTimeInput,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  List,
  NullableBooleanInput,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useTranslate,
} from "react-admin";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { AccountType } from "../react-admin-loopback/api/shared-constants";
import BackButton from "../components/BackButton";

const RHFilter = (props: any) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <ReferenceInput
        source="accountId"
        perPage= { null }
        sort={{ field: "nom", order: "ASC" }}
        reference="Accounts"
        alwaysOn
        filter={{ type: AccountType.CONDUCTEUR }}
      >
        <SelectInput optionText="nom" />
        {/*<AutocompleteInput optionText="nom"/>*/}
      </ReferenceInput>
      {/*<SelectInput source="date"  alwaysOn/>*/}
    </Filter>
  );
};

export const CustomForm = (props) => {
  const translate = useTranslate();
  return (
    <div className="w-100">
      <div className="d-flex flex-wrap">
        <div className="col-12 col-sm-6">
          <ReferenceInput
            source="accountId"
            reference="Accounts"
            perPage = { null }
            sort={{ field: "nom", order: "ASC" }}
            label={translate("commons.driver")}
            filter={{ type: AccountType.CONDUCTEUR }}
            fullWidth
          >
            <SelectInput optionText="nom" />
          </ReferenceInput>
        </div>
        <div className="col-12 col-sm-6">
          <DateInput
            source="date"
            label={translate("commons.date")}
            fullWidth
          />
        </div>
        <div className="col-12 col-sm-6">
          <DateTimeInput
            source="prisePoste"
            label={translate("commons.prisePoste")}
            fullWidth
          />
        </div>
        <div className="col-12 col-sm-6">
          <DateTimeInput
            source="finPoste"
            label={translate("commons.finPoste")}
            fullWidth
          />
        </div>
        <div className="col-12 col-sm-6">
          <TextInput
              source="text"
              label={translate("commons.text")}
              fullWidth
          />
        </div>
        <div className="col-12 col-sm-6">
        </div>
        <div className="col-12 col-sm-6">
          <TextInput
            source="type"
            label={translate("commons.type")}
            fullWidth
            disabled
          />
        </div>
        <div className="col-12 col-sm-6">
          <TextInput
              source="typeReelle"
              label={translate("commons.typeReelle")}
              fullWidth
          />
        </div>
        <div className="col-12 col-sm-6">
          <TextInput
            source="codePostal"
            label={translate("commons.codePostal")}
            fullWidth
            disabled
          />
        </div>
        <div className="col-12 col-sm-6">
          <TextInput
              source="codePostalReelle"
              label={translate("commons.codePostalReelle")}
              fullWidth
          />
        </div>
        <div className="col-12 col-sm-6">
          <TextInput
            source="ville"
            label={translate("commons.ville")}
            fullWidth
            disabled
          />
        </div>
        <div className="col-12 col-sm-6">
          <TextInput
              source="villeReelle"
              label={translate("commons.villeReelle")}
              fullWidth
          />
        </div>
        <div className="col-12 col-sm-6">
          <TextInput
            source="pays"
            label={translate("commons.pays")}
            fullWidth
            disabled
          />
        </div>
        <div className="col-12 col-sm-6">
          <TextInput
              source="paysReelle"
              label={translate("commons.paysReelle")}
              fullWidth
          />
        </div>
        <div className="col-12 col-sm-6">
          <TextInput
            source="montant"
            label={translate("commons.montant")}
            fullWidth
            disabled
          />
        </div>
        <div className="col-12 col-sm-6">
          <TextInput
              source="montantReelle"
              label={translate("commons.montantReelle")}
              fullWidth
          />
        </div>
        <div className="col-12 col-sm-6">
          <BooleanInput
            label={translate("commons.majoration")}
            source="majoration"
            disabled
          />
        </div>
        <div className="col-12 col-sm-6">
          <BooleanInput
              source="majorationReelle"
              label={translate("commons.majorationReelle")}
          />
        </div>
        <div className="col-12 col-sm-6">
          <BooleanInput
            label={translate("commons.activation")}
            source="activation"
            disabled
          />
        </div>
        <div className="col-12 col-sm-6">
          <BooleanInput
              label={translate("commons.activationReelle")}
              source="activationReelle"
              fullWidth
          />
        </div>
      </div>
    </div>
  );
};
export const RHList = (props) => {
  const translate = useTranslate();
  return (
    <List {...props} filters={<RHFilter />} exporter={false}>
      <Datagrid>
        <TextField source="id" />
        <ReferenceField source="accountId" reference="Accounts">
          <TextField source="nom" label={translate("commons.driver")} />
        </ReferenceField>
        <DateField source="date" label={translate("commons.date")} />
        <DateField
          source="prisePoste"
          label={translate("commons.prisePoste")}
        />
        <DateField source="finPoste" label={translate("commons.finPoste")} />
        <TextField source="type" label={translate("commons.type")} />
        <TextField source="text" label={translate("commons.text")} />
        <NumberField
          source="codePostal"
          label={translate("commons.codePostal")}
        />
        <TextField source="ville" label={translate("commons.ville")} />
        <TextField source="pays" label={translate("commons.pays")} />
        <TextField source="montant" label={translate("commons.montant")} />
        <DateField source="updatefrais" label={translate("commons.updatefrais")} showTime />
        <BooleanField
          source="majoration"
          label={translate("commons.majoration")}
        />
        <BooleanField
          source="activation"
          label={translate("commons.activation")}
        />
        <TextField
          source="majorationReelle"
          label={translate("commons.majorationReelle")}
        />
        <TextField
          source="codePostalReelle"
          label={translate("commons.codePostalReelle")}
        />
        <TextField
          source="villeReelle"
          label={translate("commons.villeReelle")}
        />
        <TextField
          source="paysReelle"
          label={translate("commons.paysReelle")}
        />
        <TextField
          source="montantReelle"
          label={translate("commons.montantReelle")}
        />
        <TextField
          source="typeReelle"
          label={translate("commons.typeReelle")}
        />
        <BooleanField
          source="activationReelle"
          label={translate("commons.activationReelle")}
        />
        <DateField source="updateuser" label={translate("commons.updateuser")} showTime />
        <DateField source="updatedAt" label={translate("commons.updatedAt")} showTime />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};

export const RHEdit = (props: any) => {
  const translate = useTranslate();
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mt-3">
        {/* <Link to="/RHs" className="text-decoration-none">
          <ArrowBackIcon /> {translate("button.back")}
        </Link> */}
        <BackButton/>
        <h3>{"RH Edit"}</h3>
        <b></b>
      </div>
      <Edit {...props}>
        <SimpleForm>
          <CustomForm />
        </SimpleForm>
      </Edit>
    </div>
  );
};
export const RHCreate = (props: any) => {
  const translate = useTranslate();
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mt-3">
        {/* <Link to="/RHs" className="text-decoration-none">
          <ArrowBackIcon /> {translate("button.back")}
        </Link> */}
        <BackButton/>
        <h3>{"RH Create"}</h3>
        <b></b>
      </div>
      <Create {...props}>
        <SimpleForm redirect="/RHs">
          <CustomForm />
        </SimpleForm>
      </Create>
    </div>
  );
};
