import { API_URL } from "../../variables/constant";
import { Api } from './api';

export class LoopbackAPI extends Api {
    constructor() {
        const lbtoken = localStorage.getItem("lbtoken");
        const token = lbtoken ? JSON.parse(lbtoken).value?.id : undefined;

        super({
            baseUrl: API_URL,
            baseApiParams: {
                headers:
                    {
                        authorization: token,
                        'Content-Type': 'application/json'
                    }
            }
        });
    }
}
