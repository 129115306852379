import React, {useState} from "react";
import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    DateField,
    DateTimeInput,
    Edit,
    Filter,
    List,
    NumberField,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
    useDataProvider,
    useListContext,
    useRefresh,
    useTranslate
} from 'react-admin';
import {AccountType, EtapePay,} from "../react-admin-loopback/api/shared-constants";
import {parse} from "query-string";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import {useRedirect} from "ra-core";
import DeleteIcon from '@material-ui/icons/Delete';
import {Modal} from 'react-bootstrap';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import {CircularProgress} from '@material-ui/core';
import BackButton from "../components/BackButton";

const EtapeFilter = (props: any) => {

    const translate = useTranslate();
    // const etapeName = Object.keys(EtapeType).map((item) => {
    //     return {id: item, name: EtapeType[item]};
    // });
    return (
        <Filter {...props}>
            <ReferenceInput
                source="accountId"
                reference="Accounts"
                perPage={null}
                label={translate("commons.name")}
                alwaysOn
                sort={{field: "nom", order: "ASC"}}
            >
                <SelectInput optionText="nom"/>
            </ReferenceInput>
            <SelectInput
                source="etapeStatus"
                choices={[
                    {id: "done", name: translate("commons.etapesDone")},
                    {id: "undone", name: translate("commons.etapesUndone")}
                ]}
                alwaysOn
            />
            <NumberInput source="missionId" label={translate("commons.missionId")} alwaysOn/>

            {/* <ReferenceInput
        source="missionId"
        reference="Missions"
        label={translate("commons.missionId")}
        alwaysOn
        sort={{ field: "updatedAt", order: "DESC" }}
      >
        <NumberInput source="id" alwaysOn />
      </ReferenceInput> */}
            <TextInput source="adresse" alwaysOn/>
        </Filter>
    );
};

export const CustomForm = () => {
    const translate = useTranslate();
    const choicePays = Object.keys(EtapePay).map((item) => {
        return {id: item, name: EtapePay[item]};
    });
    // const units = Object.keys(Unit).map((item) => {
    //     return {id: item, name: Unit[item]};
    // });
    // const actions = Object.keys(EtapeAction).map((item) => {
    //     return {id: item, name: EtapeAction[item]};
    // });
    // const etapeName = Object.keys(EtapeType).map((item) => {
    //     return {id: item, name: EtapeType[item]};
    // });
    return (
        <div className="w-100">
            <div className="d-flex flex-wrap">
                <div className="col-12 col-sm-6 col-md-4">
                    <ReferenceInput
                        source="accountId"
                        reference="Accounts"
                        perPage={null}
                        filter={{type: AccountType.CONDUCTEUR}}
                        sort={{field: "nom", order: "ASC"}}
                        alwaysOn
                        fullWidth
                    >
                        <SelectInput optionText="nom"/>
                    </ReferenceInput>
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <ReferenceInput source="moteurId" reference="Moteurs" fullWidth>
                        <SelectInput optionText="id"/>
                    </ReferenceInput>
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <ReferenceInput source="tracteId" reference="Tractes" fullWidth>
                        <SelectInput optionText="id"/>
                    </ReferenceInput>
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <ReferenceInput
                        source="missionId"
                        reference="Missions"
                        label={translate("commons.missionId")}
                        fullWidth
                    >
                        <SelectInput optionText="id"/>
                    </ReferenceInput>
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <ReferenceInput
                        source="missionId"
                        reference="Missions"
                        label={translate("commons.mission")}
                        fullWidth
                    >
                        <SelectInput optionText="ot"/>
                    </ReferenceInput>
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <SelectInput
                        source="pays"
                        choices={choicePays}
                        label={translate("commons.pays")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <TextInput source="eta" label={translate("commons.eta")} fullWidth/>
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <TextInput
                        source="refClient"
                        label={translate("commons.refClient")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <DateTimeInput
                        locales="fr-FR"
                        source="date"
                        label={translate("commons.date")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <DateTimeInput
                        locales="fr-FR"
                        source="rdvDebut"
                        label={translate("commons.rdvDebut")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <DateTimeInput
                        source="rdvFin"
                        label={translate("commons.rdvFin")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <TextInput
                        source="nomTypeDetape"
                        label={translate("commons.nomTypeDetape")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <TextInput
                        source="newNomTypeDetape"
                        label={translate("commons.newNomTypeDetape")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <TextInput
                        source="quantity"
                        label={translate("commons.quantity")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <TextInput
                        source="quantityReelle"
                        label={translate("commons.quantityReelle")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <TextInput
                        source="unit"
                        label={translate("commons.unit")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <TextInput
                        source="nature"
                        label={translate("commons.nature")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <TextInput
                        source="adresse"
                        label={translate("commons.addresse")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <TextInput
                        source="codePostal"
                        label={translate("commons.codePostal")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <TextInput
                        source="pays"
                        label={translate("commons.pays")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <NumberInput
                        source="telephone"
                        label={translate("commons.serialNumber")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <DateTimeInput
                        source="heureArrive"
                        label={translate("commons.heureArrive")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <DateTimeInput
                        source="heureArriveReelle"
                        label={translate("commons.heureArriveReelle")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <DateTimeInput
                        source="heureDebut"
                        label={translate("commons.heureDebut")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <DateTimeInput
                        source="heureDebutReelle"
                        label={translate("commons.heureDebutReelle")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <DateTimeInput
                        source="heureFin"
                        label={translate("commons.heureFin")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <DateTimeInput
                        source="heureFinReelle"
                        label={translate("commons.heureFinReelle")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <DateTimeInput
                        source="saisiearrivesite"
                        label={translate("commons.saisiearrivesite")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <DateTimeInput
                        source="saisiedebutetape"
                        label={translate("commons.saisiedebutetape")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <DateTimeInput
                        source="saisiefinetape"
                        label={translate("commons.saisiefinetape")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <TextInput
                        source="client"
                        label={translate("commons.client")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <TextInput
                        source="metreLineaire"
                        label={translate("commons.metreLineaire")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <TextInput
                        source="poidsKg"
                        label={translate("commons.poidsKg")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <TextInput
                        source="ref2"
                        label={translate("commons.ref2")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <TextInput
                        source="ref3"
                        label={translate("commons.ref3")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <TextInput
                        source="contrainte"
                        label={translate("commons.contrainte")}
                        fullWidth
                    />
                </div>
                {/*<div className="col-12 col-sm-6 col-md-4">*/}
                {/*<SelectInput choices={actions} source="action" label={translate('commons.action')} fullWidth/>*/}
                {/*</div>*/}
                <div className="col-12 col-sm-6 col-md-4">
                    <TextInput
                        source="particularite"
                        label={translate("commons.particularite")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <TextInput
                        source="statut"
                        label={translate("commons.statut")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <TextInput
                        source="raisonSociale"
                        label={translate("commons.raisonSociale")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <DateTimeInput
                        source="dateHeureCmr"
                        label={translate("commons.dateHeureCmr")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <DateTimeInput
                        source="dateFinReelle"
                        label={translate("commons.dateFinReelle")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <TextInput
                        source="ville"
                        label={translate("commons.ville")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <TextInput
                        source="tracteParcTracteReel"
                        label={translate("commons.tracteParcTracteReel")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <TextInput
                        source="echangepal"
                        label={translate("commons.echangepal")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <TextInput
                        source="echangepalreel"
                        label={translate("commons.echangepalreel")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <BooleanInput
                        source="document"
                        label={translate("commons.document")}
                        fullWidth
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-4">
                    <BooleanInput
                        source="gestionpalettes"
                        label={translate("commons.gestionpalettes")}
                        fullWidth
                    />
                </div>
                {/*<div className="col-12 col-sm-6 col-md-4">*/}
                {/*<SelectInput source="unit" choices={units} label={translate('commons.unit')} fullWidth/>*/}
                {/*</div>*/}
            </div>
        </div>
    );
};

const CustomEditButton = (props) => {
    const redirect = useRedirect();
    const translate = useTranslate();
    return (
        <Button
            onClick={() => redirect(`Etapes/${props.record.id}`)}
            color="primary"
            startIcon={<EditIcon/>}
        >
            {translate("commons.edit")}
        </Button>
    );
};

const CustomDeleteAction = (props) => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const dataProvides = useDataProvider();
    const translate = useTranslate();
    const refresh = useRefresh();

    const remove = () => {
        setLoading(true);
        dataProvides.delete('Etapes', {id: props.record.id})
            .then(({data}) => {
                console.log(data);
                setLoading(false);
                setShow(false);
                refresh();
            }).catch(() => {
            setLoading(false);
        });
    }
    return (
        <>
            <Button style={{
                color: '#eb4034'
            }}
                    startIcon={<DeleteIcon/>} onClick={handleShow}>
                {translate("commons.delete")}
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{`${translate("commons.deleteTitle")} #${props.record.id}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading && (<CircularProgress/>)}
                    {translate("commons.confirmDelete")}
                </Modal.Body>
                <Modal.Footer>
                    <Button startIcon={<CancelIcon/>} color="inherit" onClick={handleClose}>
                        {translate("commons.cancel")}
                    </Button>
                    <Button startIcon={<CheckCircleOutlineIcon/>} color="secondary" onClick={remove}>
                        {translate("commons.confirm")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
    // return (
    //     <Button style={{
    //       color:  '#eb4034'
    //     }}
    //             startIcon={<DeleteIcon />} onClick={() => removeEtape(props.record, dataProvides, refresh)}> {translate("commons.delete")}</Button>)
};

const CustomMultiDeleteAction = (props) => {
    const {selectedIds} = props;
    const {onUnselectItems} = useListContext();

    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    const translate = useTranslate();
    const dataProvides = useDataProvider();
    const refresh = useRefresh();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onRemoveMulti = () => {
        setLoading(true);
        Promise.all(selectedIds.map((id) => dataProvides.delete('Etapes', {id: id})))
            .then((response) => {
                console.log(response);
                setLoading(false);
                setShow(false);
                refresh();
                onUnselectItems();
            }).catch(() => {
            setLoading(false);
        });
    }
    return (
        <>
            <Button style={{
                color: '#eb4034'
            }}
                    startIcon={<DeleteIcon/>} onClick={handleShow}>
                {translate("commons.delete")}
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{`${translate("commons.deleteTitle")} #${selectedIds.length} ${translate("commons.items")}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading && (<CircularProgress/>)}
                    {translate("commons.confirmDelete")}
                </Modal.Body>
                <Modal.Footer>
                    <Button startIcon={<CancelIcon/>} color="inherit" onClick={handleClose}>
                        {translate("commons.cancel")}
                    </Button>
                    <Button startIcon={<CheckCircleOutlineIcon/>} color="secondary" onClick={onRemoveMulti}>
                        {translate("commons.confirm")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

// const removeEtape = (data, dataProvides, refresh) => {
//
//   dataProvides.delete('Etapes', {id:  data.id})
//       .then(({data}) => {
//         console.log(data);
//         refresh();
//       })
// }

const PostBulkActionButtons = (props) => (
    <>
        <CustomMultiDeleteAction {...props} />
    </>
);

export const EtapeList = (props) => {
    const translate = useTranslate();
    const {missionId: post_id_string} = parse(props.location.search);
    const missionId = post_id_string
        ? parseInt(post_id_string as string, 10)
        : "";
    console.log(missionId);
    // const [filter, setFilter] = useState({type: AccountType.CONDUCTEUR});
    return (
        <List
            {...props}
            filters={<EtapeFilter/>}
            // filter={missionId > 0 ? { missionId: missionId } : {}}
            sort={{field: "missionId", order: "DESC"}}
            exporter={false}
            bulkActionButtons={<PostBulkActionButtons/>}
        >
            <Datagrid>
                <TextField source="id"/>
                <ReferenceField
                    source="accountId"
                    reference="Accounts"
                    label={translate("commons.name")}
                >
                    <TextField source="nom" label={translate("commons.name")}/>
                </ReferenceField>
                <ReferenceField
                    source="moteurId"
                    reference="Moteurs"
                    label={translate("commons.moteur")}
                >
                    <TextField source="id"/>
                </ReferenceField>
                <ReferenceField
                    source="tracteId"
                    reference="Tractes"
                    label={translate("commons.tracte")}
                >
                    <TextField source="id"/>
                </ReferenceField>
                <ReferenceField
                    sortable={false}
                    source="missionId"
                    reference="Missions"
                    label={translate("commons.missionId")}
                >
                    <TextField source="id"/>
                </ReferenceField>
                <ReferenceField
                    sortable={false}
                    source="missionId"
                    reference="Missions"
                    label={translate("commons.mission")}
                >
                    <TextField source="ot"/>
                </ReferenceField>
                <TextField source="eta" label={translate("commons.eta")}/>
                <TextField source="refClient" label={translate("commons.refClient")}/>
                <DateField showTime source="date" label={translate("commons.date")}/>
                <DateField
                    showTime
                    source="rdvDebut"
                    label={translate("commons.rdvDebut")}
                />
                <DateField
                    showTime
                    source="rdvFin"
                    label={translate("commons.rdvFin")}
                />
                <TextField
                    source="nomTypeDetape"
                    label={translate("commons.nomTypeDetape")}
                />
                <TextField
                    source="newNomTypeDetape"
                    label={translate("commons.newNomTypeDetape")}
                />
                <NumberField
                    source="quantity"
                    label={translate("commons.quantity")}
                    headerClassName="p-sm"
                    cellClassName={"p-sm"}
                />
                <TextField
                    source="quantityReelle"
                    label={translate("commons.quantityReelle")}
                    headerClassName="p-sm"
                    cellClassName={"p-sm"}
                />
                <TextField
                    source="unit"
                    label={translate("commons.unit")}
                    headerClassName="p-sm"
                    cellClassName={"p-sm"}
                />
                <TextField source="nature" label={translate("commons.nature")}/>
                <TextField
                    source="adresse"
                    label={translate("commons.addresse")}
                    headerClassName="w-120"
                />
                <TextField
                    source="codePostal"
                    label={translate("commons.codePostal")}
                />
                <TextField source="pays" label={translate("commons.pays")}/>
                <TextField
                    source="telephone"
                    label={translate("commons.serialNumber")}
                />
                <DateField
                    showTime
                    source="heureArrive"
                    label={translate("commons.heureArrive")}
                />
                <DateField
                    showTime
                    source="heureArriveReelle"
                    label={translate("commons.heureArriveReelle")}
                />
                <DateField
                    showTime
                    source="heureDebut"
                    label={translate("commons.heureDebut")}
                />
                <DateField
                    showTime
                    source="heureDebutReelle"
                    label={translate("commons.heureDebutReelle")}
                />
                <DateField
                    showTime
                    source="heureFin"
                    label={translate("commons.heureFin")}
                />
                <DateField
                    showTime
                    source="heureFinReelle"
                    label={translate("commons.heureFinReelle")}
                />
                <DateField
                    showTime
                    source="saisiearrivesite"
                    label={translate("commons.saisiearrivesite")}
                />
                <DateField
                    showTime
                    source="saisiedebutetape"
                    label={translate("commons.saisiedebutetape")}
                />
                <DateField
                    showTime
                    source="saisiefinetape"
                    label={translate("commons.saisiefinetape")}
                />
                <TextField
                    showTime
                    source="client"
                    label={translate("commons.client")}
                    headerClassName="w-120"
                />
                <NumberField
                    source="metreLineaire"
                    label={translate("commons.metreLineaire")}
                />
                <NumberField source="poidsKg" label={translate("commons.poidsKg")}/>
                <TextField source="ref2" label={translate("commons.ref2")}/>
                <TextField source="ref3" label={translate("commons.ref3")}/>
                <TextField
                    source="contrainte"
                    label={translate("commons.contrainte")}
                />
                <TextField
                    source="particularite"
                    label={translate("commons.particularite")}
                />
                <BooleanField source="statut" label={translate("commons.statut")}/>
                <TextField
                    source="raisonSociale"
                    label={translate("commons.raisonSociale")}
                />
                <DateField
                    showTime
                    source="dateHeureCmr"
                    label={translate("commons.dateHeureCmr")}
                />
                <DateField
                    showTime
                    source="dateFinReelle"
                    label={translate("commons.dateFinReelle")}
                />
                <TextField source="ville" label={translate("commons.ville")}/>
                <DateField
                    showTime
                    source="tracteParcTracteReel"
                    label={translate("commons.tracteParcTracteReel")}
                />
                {/*<EditButton/>*/}
                <CustomEditButton/>
                <CustomDeleteAction/>
                {/*<DeleteButton redirect={`/Etapes/${props.record.id}`} />*/}
            </Datagrid>
        </List>
    );
};

export const EtapeCreate = (props: any) => {
    const translate = useTranslate();
    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mt-3">
                {/* <Link to="/EtapeOfDriver" className="text-decoration-none">
          <ArrowBackIcon /> {translate("button.back")}
        </Link> */}
                <BackButton/>
                <h3>{translate("etape.create")}</h3>
                <b></b>
            </div>
            <Create {...props}>
                <SimpleForm redirect="/EtapeOfDriver">
                    <CustomForm/>
                </SimpleForm>
            </Create>
        </div>
    );
};

export const EtapeEdit = (props: any) => {
    const translate = useTranslate();
    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mt-3">
                {/* <Link to="/EtapeOfDriver" className="text-decoration-none">
          <ArrowBackIcon /> {translate("button.back")}
        </Link> */}
                <BackButton/>
                <h3>{translate("etape.edit")}</h3>
                <b></b>
            </div>
            <Edit {...props}>
                <SimpleForm>
                    <CustomForm/>
                </SimpleForm>
            </Edit>
        </div>
    );
};
