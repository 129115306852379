import React, { useState } from "react";
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  ImageField,
  ImageInput,
  List,
  NullableBooleanInput,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useTranslate,
} from "react-admin";
import { API_URL } from "../variables/constant";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  AccountType,
  LieuType,
} from "../react-admin-loopback/api/shared-constants";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import BackButton from "../components/BackButton";

const EtatFilter = (props: any) => {
  const translate = useTranslate();
  const choices = Object.keys(LieuType).map((it) => {
    return { id: it, name: it };
  });
  return (
    <Filter {...props}>
      <SelectInput
        source="lieu"
        choices={choices}
        label={translate("commons.lieu")}
        fullWidth
        alwaysOn
      />
      <ReferenceInput
        source="accountId"
        reference="Accounts"
        sort={{ field: "nom", order: "ASC" }}
        label={translate("commons.driver")}
        fullWidth
        alwaysOn
      >
        <SelectInput optionText="nom" />
      </ReferenceInput>
    </Filter>
  );
};

const ImagesShowInTable = (props) => {
  return props.record.image && props.record.image.length > 0 ? (
    <div className="d-flex align-items-center justify-content-center">
      <img
        src={`${API_URL}/Containers/photos/download/${props?.record?.image}`}
        alt=""
        className="mr-2 w-100"
      />
    </div>
  ) : null;
};

const ImagesShowInEdit = (props) => {
  return (
    <div className="d-flex align-items-center justify-content-center fix-image">
      <img src={`${API_URL}/Containers/photos/download/${props?.url}`} alt="" />
    </div>
  );
};

const HaveEyeIconField = (props) => {
  const useStyles = makeStyles({
    iconEyes: {
      color: "rgb(204, 204, 0)",
      fontSize: 32,
    },
  });
  const classes = useStyles();
  return <VisibilityOutlinedIcon className={classes.iconEyes} />;
};

const CustomFormEtat = (props) => {
  const translate = useTranslate();
  const choices = Object.keys(LieuType).map((it) => {
    return { id: it, name: it };
  });
  return (
    <div className="d-flex flex-wrap">
      {props?.isEdit && (
        <div className="col-12">
          <ImagesShowInEdit url={props?.record?.image} fullWidth />
        </div>
      )}
      <div className="col-12">
        <ImageInput
          source="pictures"
          label={translate("commons.uploadImage")}
          accept="image/*"
        >
          <ImageField source="src" title="image" />
        </ImageInput>
      </div>
      <div className="col-12 col-sm-6">
        <SelectInput
          source="lieu"
          choices={choices}
          label={translate("commons.lieu")}
          fullWidth
        />
      </div>
      <div className="col-12 col-sm-6">
        <ReferenceInput
          source="accountId"
          reference="Accounts"
          sort={{ field: "nom", order: "ASC" }}
          label={translate("commons.driver")}
          fullWidth
        >
          <SelectInput optionText="nom" />
        </ReferenceInput>
      </div>
      <div className="col-12">
        <NumberInput
          source="tripId"
          label={translate("commons.tripId")}
          fullWidth
        />
      </div>
      <div className="col-12">
        <TextInput
          multiline
          source="tripInformation"
          label={translate("commons.informationTrip")}
          fullWidth
        />
      </div>
      {/*<div className="col-12 col-sm-6">*/}
      {/*    <NullableBooleanInput label={translate('commons.problem')} source="problem" />*/}
      {/*</div>*/}
      <div className="col-12">
        <TextInput
          source="note"
          label={translate("commons.note")}
          multiline
          fullWidth
        />
      </div>
    </div>
  );
};

const CustomShowData = (props) => {
  const translate = useTranslate();
  try {
    const data = JSON.parse(props.record.tripInformation);
    const dataNeedView =
      "Nom: " +
      data.sie.nom +
      "," +
      "Moteur: " +
      data.moteur +
      ", Ot: " +
      data.ot +
      ", Chargements: " +
      data.tab_chargements[0].adresse +
      ", Livraisons: " +
      data.tab_livraisons[0].adresse;
    return <span className={"limit-text"}>{JSON.stringify(dataNeedView)}</span>;
  } catch (e) {
    return (
      <span className={"limit-text"}>{translate("commons.dataNotValid")}</span>
    );
  }
};

export const EtatList = (props) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      filters={<EtatFilter />}
      sort={{ field: "updatedAt", order: "DESC" }}
    >
      <Datagrid rowClick="edit" style={{ tableLayout: "fixed" }}>
        <TextField source="id" />
        {/*<ImagesShowInTable source="image" label={translate('commons.image')} headerClassName="w-40"/>*/}
        <HaveEyeIconField label={translate("commons.image")} />
        <TextField source="lieu" label={translate("commons.lieu")} />
        {/*<BooleanField source="problem" label={translate('commons.problem')}/>*/}
        {/*<TextField source="tripId" label={translate('commons.tripId')}/>*/}
        {/*<TextField source="conducteurId" label={translate('commons.conducteurId')}/>*/}
        <ReferenceField
          source="accountId"
          reference="Accounts"
          label={translate("commons.driver")}
          headerClassName="w-8"
        >
          <TextField source="nom" />
        </ReferenceField>
        {/*<TextField source="tripInformation" label={translate('commons.informationTrip')}/>*/}
        <CustomShowData
          label={translate("commons.informationTrip")}
          headerClassName="w-25 p-sm"
          className="limit-text"
          cellClassName={"p-sm"}
        />
        <TextField
          source="note"
          label={translate("commons.note")}
          headerClassName="w-20 p-sm"
          className="limit-text"
          cellClassName={"p-sm"}
        />
        <DateField source="createdAt" label={translate("commons.createdAt")} />
        <DateField source="updatedAt" label={translate("commons.updatedAt")} />
        <EditButton className="btn-edit" />
      </Datagrid>
    </List>
  );
};

export const EtatCreate = (props: any) => {
  const translate = useTranslate();
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mt-3">
        {/* <Link to="/EtatDeLieuxes" className="text-decoration-none">
          <ArrowBackIcon /> {translate("button.back")}
        </Link> */}
        <BackButton/>
        <h3>{translate("etat.createEtat")}</h3>
        <b></b>
      </div>
      <Create {...props}>
        <SimpleForm redirect="/EtatDeLieuxes" className="w-100">
          <CustomFormEtat />
        </SimpleForm>
      </Create>
    </div>
  );
};

export const EtatEdit = (props: any) => {
  const translate = useTranslate();
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mt-3">
        {/* <Link to="/EtatDeLieuxes" className="text-decoration-none">
          <ArrowBackIcon /> {translate("button.back")}
        </Link> */}
        <BackButton/>
        <h3>{translate("etat.editEtat")}</h3>
        <b></b>
      </div>
      <Edit {...props}>
        <SimpleForm redirect="/EtatDeLieuxes" className="w-100">
          <CustomFormEtat isEdit={true} />
        </SimpleForm>
      </Edit>
    </div>
  );
};
