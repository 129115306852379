var passwordValidator = require('password-validator');
export function validatorPassword(password: any): [] {
  var schema = new passwordValidator();
  schema
    .is().min(8)
    .is().max(100)
    .has().uppercase()
    .has().lowercase()
    .has().digits(2)
  let validate = schema.validate(password, { details: true });
  return validate;
}
export const statusCodeValidatorPasswordError = 422;
export const errorMessageValidatorPassword = "Your password must length between 8 and 100 , have uppercase, lowercase letters and least 2 digits!";


