import React from 'react';
import { API_URL } from '../variables/constant';

export interface PropUrlImage {
    containerId: string;
    imageUrl: string;
    type?: string;
    record?: any;
    label?: string;
}
export const ShowImages = (props: PropUrlImage) => {
    const style = {
        img_size: {
            width: '8rem',
            height: '3rem'
        }
    };
    if (props.record) {
        // For case show image in list page
        return (
            props.record.logo ? <img style={style.img_size} src={`${API_URL}/Containers/${props.containerId}/download/${props.record.logo}`}/> : null
        );
    } else {
        return (
            props.imageUrl ? <img style={style.img_size} src={`${API_URL}/Containers/${props.containerId}/download/${props.imageUrl}`}/> : null
        );
    }
};
