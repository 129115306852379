import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useLogin, useNotify, useTranslate , useRedirect} from 'ra-core';
import React, { useState } from 'react';
import { Notification } from 'react-admin';
import { Field, withTypes } from 'react-final-form';
import ModalValidatePassword from '../admin/modal-validate-password';
import { storage } from '../react-admin-loopback';
import { LoopbackAPI } from '../react-admin-loopback/api/loopback-api';
import { validatorPassword } from '../utils/passwordValidator';
import { PasswordInput } from 'react-admin';


var passwordValidator = require('password-validator');


const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F2F2F2',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  card: {
    minWidth: 450,
    // marginTop: '6em',
    width: '25%',
    paddingBottom: '1rem',
    textAlign: 'center'
    // height: '30vh'
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center'
  },
  form: {
    padding: '0 1em 1em 1em'
  },
  input: {
    marginTop: '1em',
    minWidth: 400,
  },
  actions: {
    padding: '2em 1em'
  }
}));

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);
interface FormValues {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}
const { Form } = withTypes<FormValues>();


const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  const handleSubmit = (form: FormValues) => {
    setLoading(true);
    const API = new LoopbackAPI();
    API.accounts.accountChangePassword({ oldPassword: form.oldPassword, newPassword: form.newPassword }).then(result => {
      setLoading(false)
      notify('Success', 'success');
      storage.remove('statusPassword');
      redirect('/');
    }).catch(err => {
      setLoading(false);
      let message = err?.error?.message? err.error.message : translate('alert.internalServerError')
      if(err.error.code === 'NEW_PASSWORD_IS_DIFFERENT_FROM_OLD'){
        message = translate('alert.newPasswordDifferentOld')
      }else{
        if(err.error.code === 'INVALID_PASSWORD'){
          message = translate('alert.invalidPassword')
        }
      }
      notify(message, 'error');
    })
  };

  const validate = (values: FormValues) => {
    const errors: any = {};
    let validatePassword = validatorPassword(values.newPassword)
    if (!values.oldPassword) {
      errors.oldPassword = translate('ra.validation.required');
    }
    if (validatePassword.length > 0) {
      errors.newPassword = validatePassword.map((item: any, index) => {
        return <p key={index}>{translate(`alert.${item.validation}`)}</p>
      })
    }
    if (values.newPassword !== values.confirmPassword) {
      errors.confirmPassword = translate('alert.confirmPasswordNotSame')
    }
    return errors;
  };
  const statusPassword = storage.load("statusPassword");
  const [show, setShow] = useState(true);
  const handleClose = () => {
    setShow(false);
    //storage.remove('statusPassword');
  }

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.main}>
            <Card className={classes.card}>
              <div className={classes.avatar}>
                {/* <img src={src} alt="" /> */}
                {/*<Avatar src={src} />*/}
              </div>
              {/*<div className={classes.hint}>*/}
              {/*    Hint: demo / demo*/}
              {/*</div>*/}
              <div className={classes.form}>
                <div>
                  <PasswordInput
                    className={classes.input}
                    autoFocus
                    name="oldPassword"
                    // @ts-ignore
                    component={renderInput}
                    label={translate('password.oldPassword')}
                    type="password"
                    disabled={loading}
                  />
                </div>
                <div >
                  <PasswordInput
                    className={classes.input}
                    name="newPassword"
                    // @ts-ignore
                    component={renderInput}
                    label={translate('password.newPassword')}
                    type="password"
                    disabled={loading}
                  />
                </div>
                <div >
                  <PasswordInput
                    className={classes.input}
                    name="confirmPassword"
                    // @ts-ignore
                    component={renderInput}
                    label={translate('password.confirmPassword')}
                    type="password"
                    disabled={loading}
                  />
                </div>
              </div>
              <CardActions className={classes.actions}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading}
                  fullWidth
                >
                  {loading && (
                    <CircularProgress
                      size={25}
                      thickness={2}
                    />
                  )}
                  {translate('ra.auth.sign_in')}
                </Button>
              </CardActions>
            </Card>
            <Notification />
          </div>
          <div>
          {statusPassword == 'Not strong' && <ModalValidatePassword show={show} handleClose={handleClose} />}
          </div>
        </form>
      )}
    />
  )
}

export default ChangePassword;