import React from 'react';
import './App.css';
import { Admin, ListGuesser, Resource } from 'react-admin';
import { API_URL } from './variables/constant';
import fetchJson from './react-admin-loopback/fetch';
import loopbackClient, { authProvider } from './react-admin-loopback';
import { MyLoginPage } from './auth/login';
import { AccountCreate, AccountEdit, AccountList } from './pages/accounts';
import { CreateRoleMapping, EditRoleMapping } from './pages/role-mappings';
import MyLayout from './admin/my-layout';
import { ServiceCreate, ServiceEdit, ServiceList } from './pages/services';
import { SocieteCreate, SocieteEdit, SocieteList } from './pages/societes';
import { AssuranceCreate, AssuranceEdit, AssuranceList } from './pages/assurances';
import 'bootstrap/dist/css/bootstrap.min.css';
import './global.scss';
import { PhotoCreate, PhotoEdit, PhotoList } from './pages/photos';
import { MediaList, MediaEdit, MediaCreate } from './pages/medias';
import { ContactCreate, ContactEdit, ContactList } from './pages/contacts';
import customRoutes from "./routes/routers"
import { ParameterCreate, ParameterEdit, ParameterList } from './pages/parameters';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from './i18n/en';
import frenchMessages from './i18n/fr';
import { ModeleCreate, ModeleEdit, ModeleList } from './pages/modeles';
import { ReseveCreate, ReseveEdit, ReseveList } from './pages/reseve';
import { EtatCreate, EtatEdit, EtatList } from './pages/etat';
import { MissionCreate, MissionEdit, MissionList } from './pages/mission';
import { EtapeCreate, EtapeEdit, EtapeList } from './pages/etape';
import { HorairesCreate, HorairesEdit, HorairesList } from './pages/horaises';
import { CmrCreate, CmrEdit, CmrList } from './pages/cmrs';
import { PleinList } from './pages/plein-carburant';

import { DnCreate, DnEdit, DnList } from './pages/dns';
import { MoteurCreate, MoteurEdit, MoteurList } from './pages/motuer';
import { TracteCreate, TracteEdit, TracteList } from './pages/tractes';
import { RHCreate, RHEdit, RHList } from './pages/rhs';
import { InAppNotificationCreate, InAppNotificationEdit, InAppNotificationList } from './pages/inAppNotifications';
import { VerifyCreate, VerifyEdit, VerifyList } from './pages/verifications';
import { TempCreate, TempEdit, TempList } from './pages/temp';
import { GuideCreate, GuideList } from './pages/guide ';
import { AppVersionCreate, AppVersionList } from './pages/app-version';
import { InstallationList } from './pages/installation';
import { GuideConducteur1Create, GuideConducteur1List } from './pages/guideConducteur1';
import { GuideConducteur2Create, GuideConducteur2List } from './pages/guideConducteur2';
import { GuideConducteur3Create, GuideConducteur3List } from './pages/guideConducteur3';
import { getPositons } from './pages/position';
import { getHistoryViePrivee } from './pages/historyvieprivee';


const withUpload = (requestHandler: (type: any, resource: any, params: any) => any) =>
    async (type: string, resource: string, params: { data: any, previousData: any }) => {
        if(resource === 'Societes' && (type === 'UPDATE' || type === 'CREATE')) {
            const { pictures, ...data } = params.data;
            if (!pictures && params.data.type !== params.previousData.type) {
                return Promise.resolve({ data: params.previousData })
            }
            if (!pictures) {
                return requestHandler(type, resource, params);
            }
            // if (!logo.rawFile.type.startsWith(params.data.type)) {
            //     throw new Error("The uploaded media does not match the selected media type");
            //     return Promise.resolve({ data: params.previousData })
            // }

            const formData = new FormData();
            formData.append('file', pictures.rawFile);
            const json = await fetchJson(`${API_URL}/Containers/societes/upload`, { method: 'POST', body: formData });
            return requestHandler(type, resource, {
                ...params, data: {
                    ...data,
                    logo: json.json.result.files.file[0].name
                }
            });
        }

        if ((type === 'UPDATE' || type === 'CREATE') && resource === 'Accounts') {
            const { pictures, ...data } = params.data;
            if (pictures && pictures.rawFile) {
                const formData = new FormData();
                formData.append('file', pictures.rawFile);
                const options = {
                    method: 'POST',
                    body: formData
                };
                if(resource === 'Accounts')
                {
                    const json = await fetchJson(`${API_URL}/Containers/avatars/upload`, options);
                    return requestHandler(type, resource, {
                        ...params, data: {
                            ...data,
                            avatar: json.json.result.files.file[0].name
                        }
                    });
                }
            }
            return requestHandler(type, resource, { ...params, data: { ...data } });
        }

        if(resource === 'Reseves' && (type === 'UPDATE' || type === 'CREATE')) {
            const { pictures, ...data } = params.data;
            // if (!pictures) {
            //     return Promise.resolve({ data: params.previousData })
            // }
            if (!pictures) {
                return requestHandler(type, resource, {...params, data: {timeStamp: (new Date().getTime())}});
            }

            // const formData = new FormData();
            // formData.append('file', pictures.rawFile);
            // const json = await fetchJson(`${API_URL}/Containers/reseves/upload`, { method: 'POST', body: formData });
            let images: any = [];
            if (data.image && data.image.length > 0) {
                images = data.image;
            }
            const fetchJsonArray: any = [];
            pictures.forEach((item) => {
                const formData = new FormData();
                formData.append('file', item.rawFile);
                fetchJsonArray.push(fetchJson(`${API_URL}/Containers/reseves/upload`, { method: 'POST', body: formData }));
            });
            const imagesJson = await Promise.all(fetchJsonArray);
            imagesJson.forEach((item: any) => {
                images.push(item.json.result.files.file[0].name);
            });
            return requestHandler(type, resource, {
                ...params, data: {
                    ...data,
                    image: images,
                    timeStamp: (new Date().getTime())
                }
            });
        }
        if(resource === 'Assurances' && (type === 'UPDATE' || type === 'CREATE')) {
            const { pictures, ...data } = params.data;
            if (!pictures) {
                return requestHandler(type, resource, {...params});
            }

            let images: any = [];
            if (data.images && data.images.length > 0) {
                images = data.images;
            }
            const fetchJsonArray: any = [];
            pictures.forEach((item) => {
                const formData = new FormData();
                formData.append('file', item.rawFile);
                fetchJsonArray.push(fetchJson(`${API_URL}/Containers/photos/upload`, { method: 'POST', body: formData }));
            });
            const imagesJson = await Promise.all(fetchJsonArray);
            imagesJson.forEach((item: any) => {
                images.push(item.json.result.files.file[0].name);
            });
            console.log(images);
            return requestHandler(type, resource, {
                ...params, data: {
                    ...data,
                    images: images
                }
            });
        }

        if(resource === 'EtatDeLieuxes' && (type === 'UPDATE' || type === 'CREATE')) {
            const { pictures, ...data } = params.data;
            if (!pictures && params.data.type !== params.previousData.type) {
                return Promise.resolve({ data: params.previousData })
            }
            if (!pictures) {
                return requestHandler(type, resource, params);
            }

            const formData = new FormData();
            formData.append('file', pictures.rawFile);
            const json = await fetchJson(`${API_URL}/Containers/photos/upload`, { method: 'POST', body: formData });
            return requestHandler(type, resource, {
                ...params, data: {
                    ...data,
                    image: json.json.result.files.file[0].name
                }
            });
        }

        if((resource === 'Photos' || resource === 'CMRs' || resource === 'DNs') && (type === 'UPDATE' || type === 'CREATE')) {
            const { pictures, ...data } = params.data;
            if (!pictures) {
                return requestHandler(type, resource, {...params, data: {timeStamp: (new Date().getTime())}});
            }

            const formData = new FormData();
            formData.append('file', pictures.rawFile);
            const json = await fetchJson(`${API_URL}/Containers/photos/upload`, { method: 'POST', body: formData });
            return requestHandler(type, resource, {
                ...params, data: {
                    ...data,
                    image: json.json.result.files.file[0].name,
                    timeStamp: (new Date().getTime())
                }
            });

            // let images: any = [];
            // if (data.image && data.image.length > 0) {
            //     images = data.image;
            // }
            // const fetchJsonArray: any = [];
            // pictures.forEach((item) => {
            //     const formData = new FormData();
            //     formData.append('file', item.rawFile);
            //     fetchJsonArray.push(fetchJson(`${API_URL}/Containers/photos/upload`, { method: 'POST', body: formData }));
            // });
            // const imagesJson = await Promise.all(fetchJsonArray);
            // console.log(imagesJson);
            // imagesJson.forEach((item: any) => {
            //     images.push(item.json.result.files.file[0].name);
            // });
            // return requestHandler(type, resource, {
            //     ...params, data: {
            //         ...data,
            //         image: images
            //     }
            // });
        }

        if(resource === 'Media' && (type === 'UPDATE' || type === 'CREATE')) {
            const { media, ...data } = params.data;
            if (!media && params.data.type !== params.previousData.type) {
                return Promise.resolve({ data: params.previousData })
            }
            if (!media) {
                return requestHandler(type, resource, params);
            }

            // if (!media.rawFile.type.startsWith(params.data.type)) {
            //     throw new Error("The uploaded media does not match the selected media type");
            //     return Promise.resolve({ data: params.previousData })
            // }

            const formData = new FormData();
            formData.append('file', media.rawFile);
            const json = await fetchJson(`${API_URL}/Containers/medias/upload`, { method: 'POST', body: formData });
            return requestHandler(type, resource, {
                ...params, data: {
                    ...data,
                    source: json.json.result.files.file[0].name
                }
            });
        }

        if(resource === 'Parametres' && (type === 'UPDATE' || type === 'CREATE')) {
            const { guide, ...data } = params.data;
            if (!guide) {
                return requestHandler(type, resource, params);
            }
            const formData = new FormData();
            formData.append('file', guide.rawFile);
            const json = await fetchJson(`${API_URL}/Containers/pdf/upload`, { method: 'POST', body: formData });
            return requestHandler(type, resource, {
                ...params, data: {
                    ...data,
                    guide: json.json.result.files.file[0].name
                }
            });
        }

        if(resource === 'GuideConducteur1' && (type === 'UPDATE' || type === 'CREATE')) {
            const { guideConducteur1, ...data } = params.data;
            if (!guideConducteur1) {
                return requestHandler(type, resource, params);
            }
            const formData = new FormData();
            formData.append('file', guideConducteur1.rawFile);
            const json = await fetchJson(`${API_URL}/Containers/pdf/upload`, { method: 'POST', body: formData });
            return requestHandler(type, resource, {
                ...params, data: {
                    ...data,
                    guideConducteur1: json.json.result.files.file[0].name
                }
            });
        }
        if(resource === 'GuideConducteur2' && (type === 'UPDATE' || type === 'CREATE')) {
            const { guideConducteur2, ...data } = params.data;
            if (!guideConducteur2) {
                return requestHandler(type, resource, params);
            }
            const formData = new FormData();
            formData.append('file', guideConducteur2.rawFile);
            const json = await fetchJson(`${API_URL}/Containers/pdf/upload`, { method: 'POST', body: formData });
            return requestHandler(type, resource, {
                ...params, data: {
                    ...data,
                    guideConducteur2: json.json.result.files.file[0].name
                }
            });
        }
        if(resource === 'GuideConducteur3' && (type === 'UPDATE' || type === 'CREATE')) {
            const { guideConducteur3, ...data } = params.data;
            if (!guideConducteur3) {
                return requestHandler(type, resource, params);
            }
            const formData = new FormData();
            formData.append('file', guideConducteur3.rawFile);
            const json = await fetchJson(`${API_URL}/Containers/pdf/upload`, { method: 'POST', body: formData });
            return requestHandler(type, resource, {
                ...params, data: {
                    ...data,
                    guideConducteur3: json.json.result.files.file[0].name
                }
            });
        }

        return requestHandler(type, resource, params);
    };
// const dataProvider = jsonServerProvider('https://jsonplaceholder.typicode.com');
const i18nProvider = polyglotI18nProvider(  locale => {
    if (locale === 'en') {
        return import('./i18n/en').then(messages => messages.default);
    }
    return frenchMessages;

    // Always fallback on english

}, 'fr');

const App = () => (
    <Admin
        i18nProvider={i18nProvider}
        dataProvider={withUpload(loopbackClient(`${API_URL}`))}
        authProvider={authProvider(`${API_URL}/Accounts/login`)}
        layout={MyLayout}
        title="Connect"
        loginPage={MyLoginPage}
        customRoutes={customRoutes}
        className="app-page"
    >
        
        <Resource name="Accounts" list={AccountList} edit={AccountEdit} create={AccountCreate}/>
        <Resource name="InAppNotifications/messageTypes" />
        <Resource name="Accounts/driver" list={AccountList} edit={AccountEdit} create={AccountCreate}/>
        <Resource name="Roles" />
        <Resource name="RoleMappings" edit={EditRoleMapping} create={CreateRoleMapping}/>
        {/*<Resource name="Services" list={ServiceList} create={ServiceCreate} edit={ServiceEdit}/>*/}
        <Resource name="Societes" list={SocieteList} edit={SocieteEdit} create={SocieteCreate}/>
        <Resource name="Assurances" list={AssuranceList} edit={AssuranceEdit} create={AssuranceCreate}/>
        <Resource name="Contacts" list={ContactList} edit={ContactEdit} create={ContactCreate}/>
        {/*<Resource name="Photos" list={PhotoList} edit={PhotoEdit} create={PhotoCreate}/>*/}
        {/*<Resource name="Media" list={MediaList} edit={MediaEdit} create={MediaCreate}/>*/}
        <Resource name="Parameters" list={ParameterList} edit={ParameterEdit} create={ParameterCreate}/>
        {/*<Resource name="Modeles" list={ModeleList} edit={ModeleEdit} create={ModeleCreate}/>*/}
        <Resource name="Reserves" list={ReseveList}/>
        <Resource name="EtatDeLieuxes" list={EtatList} edit={EtatEdit} create={EtatCreate}/>
        <Resource name="Missions" list={MissionList} edit={MissionEdit} create={MissionCreate}/>
        <Resource name="Etapes" edit={EtapeEdit}/>
        {/*edit={EtapeEdit} create={EtapeCreate}*/}
        <Resource name="EtapeOfDriver" list={EtapeList}/>
        {/*<Resource name="Horaires" list={HorairesList} edit={HorairesEdit} create={HorairesCreate}/>*/}
        <Resource name="CMRs" list={CmrList} />
        <Resource name="Moteurs" list={MoteurList} edit={MoteurEdit} create={MoteurCreate}/>
        <Resource name="Tractes" list={TracteList} edit={TracteEdit} create={TracteCreate}/>
        {/*<Resource name="CMRs" list={CmrList} edit={CmrEdit} create={CmrCreate}/>*/}
        {/*<Resource name="DNs" list={DnList} edit={DnEdit} create={DnCreate}/>*/}
        <Resource name="AppVersions" list={AppVersionList} create={AppVersionCreate}/>
        <Resource name="RHs" list={RHList} edit={RHEdit} create={RHCreate}/>
        <Resource name="InAppNotifications" list={InAppNotificationList} edit={InAppNotificationEdit} create={InAppNotificationCreate}/>
        <Resource name="NotificationTypes" />
        <Resource name="Verifications" list={VerifyList} edit={VerifyEdit} create={VerifyCreate}/>
        <Resource name="TempServices" list={TempList} edit={TempEdit} create={TempCreate}/>
        <Resource name="PleinCarburants" list={PleinList} />
        <Resource name="Positions" list={getPositons}/>
        <Resource name="HistoryViePrivees" list={getHistoryViePrivee}/>
        <Resource name="Parametres" basePatth list={GuideList} create={GuideCreate} />
        <Resource name="GuideConducteur1" list={GuideConducteur1List} create={GuideConducteur1Create} />
        <Resource name="GuideConducteur2" list={GuideConducteur2List} create={GuideConducteur2Create} />
        <Resource name="GuideConducteur3" list={GuideConducteur3List} create={GuideConducteur3Create} />
        <Resource name="Installations" list={InstallationList}/>

    </Admin>
);


export default App;
