import Avatar from "@material-ui/core/Avatar";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import React, { useCallback, useEffect, useState } from "react";
import {
  BooleanField,
  BooleanInput,
  Button,
  ChipField,
  Create,
  Datagrid,
  DateField,
  DeleteButton,
  Edit,
  EmailField,
  Filter,
  FormDataConsumer,
  FormTab,
  List,
  PasswordInput,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  SelectInput,
  SimpleForm,
  SingleFieldList,
  TabbedForm,
  TextField,
  TextInput,
  email,
  useTranslate,
} from "react-admin";
import { Link } from "react-router-dom";
// import { DateInput } from '../react-admin-date-inputs';
import {
  CircularProgress,
  Divider,
  Tab,
  Tabs,
  TextField as TextFieldMaterial,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import PhoneIcon from "@material-ui/icons/Phone";
import { parse } from "query-string";
import BackButton from "../components/BackButton";
import { LoopbackAPI } from "../react-admin-loopback/api/loopback-api";
import {
  AccountType,
  ContactType,
} from "../react-admin-loopback/api/shared-constants";
import { validatorPassword } from "../utils/passwordValidator";
import { API_URL } from "../variables/constant";
var passwordValidator = require("password-validator");

const AvatarShow = ({ record = {}, size }: any) => {
  size = size || 32;
  const extra = { label: "avatar" };
  return record.avatar ? (
    <Avatar
      {...extra}
      src={`${API_URL}/Containers/photos/download/${record.avatar}`}
      style={{
        width: size,
        height: size,
      }}
    />
  ) : null;
};

const AddRoleButton = ({ classes, record }: any) => {
  const translate = useTranslate();
  return (
    <Button
      component={Link}
      to={`/RoleMappings/create?principalId=${record.id}`}
      label={translate("commons.add")}
      title="Add"
    >
      <VpnKeyIcon />
    </Button>
  );
};

const EditRoleButton = ({ classes, record }: any) => {
  const translate = useTranslate();
  return (
    <Button
      component={Link}
      to={`/RoleMappings/${record.id}?principalId=${record.principalId}`}
      label={translate("commons.edit")}
      title="Edit"
    >
      <VpnKeyIcon />
    </Button>
  );
};

const DeleteRoleButton = (props: any) => (
  <DeleteButton {...props} redirect={`/Accounts/${props.record.id}/roles`} />
);

var resetFilter;

const AccountFilter = function (props: any) {
  if (!resetFilter && props.setFilters) {
    resetFilter = props.setFilters;
  }
  return (
    <Filter {...props}>
      <ReferenceInput
        source="id"
        label="Nom"
        reference="Accounts"
        perPage={null}
        filter={{ type: props.accounttype }}
        alwaysOn
        sort={{ field: "nom", order: "ASC" }}
      >
        <SelectInput optionText="nom" />
      </ReferenceInput>

      <ReferenceInput
        source="id"
        label="Prenom"
        reference="Accounts"
        perPage={null}
        filter={{ type: props.accounttype }}
        alwaysOn
        sort={{ field: "prenom", order: "ASC" }}
      >
        <SelectInput optionText="prenom" />
      </ReferenceInput>
      <TextInput source="email" alwaysOn />
      <ReferenceInput source="serviceId" reference="Services" label="Services">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput source="societeId" reference="Societes">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </Filter>
  );
};

const HaveEyeIconField = (props) => {
  const useStyles = makeStyles({
    iconEyes: {
      color: "rgb(204, 204, 0)",
      fontSize: 32,
    },
  });
  const classes = useStyles();
  return (
    <Link to={`/Photos?conducteurId=${props.record.id}`}>
      <VisibilityOutlinedIcon className={classes.iconEyes} />
    </Link>
  );
};
const ShowContactField = (props) => {
  const useStyles = makeStyles({
    iconEyes: {
      color: "rgb(204, 204, 0)",
      fontSize: 32,
    },
  });
  const classes = useStyles();
  return (
    <Link
      to={`/Contacts?accountId=${props.record.id}&type=${ContactType.APPELPERSONNEL}`}
    >
      <VisibilityOutlinedIcon className={classes.iconEyes} />
    </Link>
  );
};
const tabs = [
  { id: AccountType.CONDUCTEUR, name: "tabs.driver" },
  { id: AccountType.PERSONNEL, name: "tabs.personnel" },
  { id: AccountType.EXTERNE, name: "tabs.externe" },
  { id: AccountType.ADMIN, name: "tabs.admin" },
];

const EditAccountButton = ({ classes, record }: any) => {
  const translate = useTranslate();
  return (
    <Button
      component={Link}
      to={`/Accounts/${record.id}?type=${record.type}`}
      label={translate("commons.edit")}
      title="Edit"
    >
      <EditIcon />
    </Button>
  );
};

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const DialogTitle = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: 24,
  },
}))(MuiDialogActions);

const PushNotificationButton = ({ classes, record }: any) => {
  const translate = useTranslate();
  const [open, setOpen] = React.useState(false);
  const [link, setLink] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const API = new LoopbackAPI();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const pushNotification = useCallback(
    async (record) => {
      const data = {
        messageType: "GOOGLE_MEET",
        metaData: {
          url: link,
        },
        message: "Vous inviter à rejoindre un appel sur Meet",
      };
      setLoading(true);
      await API.accounts
        .accountPrototypeCreateInAppNotifications(record.id, data)
        .then((result) => {
          if (result) handleClose();
          setLoading(false);
        })
        .catch((err) => {
          handleClose();
          setLoading(false);
        });
    },
    [link, API.accounts]
  );

  const onChangeText = useCallback((params) => {
    setLink(params.target.value);
  }, []);

  return (
    <div>
      <Button label={translate("commons.google_meet")} onClick={handleOpen}>
        <PhoneIcon />
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            style={{
              position: "absolute",
              right: 0,
              top: 0,
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>{" "}
        </DialogTitle>
        <DialogContent dividers>
          <TextFieldMaterial
            placeholder={translate("commons.link_url")}
            variant="outlined"
            onChange={onChangeText}
            style={{ width: 400 }}
          />
        </DialogContent>
        <DialogActions>
          {!loading && (
            <Button
              label={translate("commons.send")}
              onClick={() => pushNotification(record)}
            ></Button>
          )}
          {loading && <CircularProgress size={25} thickness={2} />}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const AccountList = (props: any) => {
  const translate = useTranslate();
  const [filter, setFilter] = useState({ type: AccountType.CONDUCTEUR });
  const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
    if (resetFilter) resetFilter({});
    setFilter({ type: value });
  };
  return (
    <div className="mt-4">
      <Tabs
        variant="fullWidth"
        centered
        value={filter.type}
        indicatorColor="primary"
        onChange={handleChange}
      >
        {tabs.map((choice) => (
          <Tab
            key={choice.id}
            label={translate(choice.name)}
            value={choice.id}
          />
        ))}
      </Tabs>
      <Divider />
      <List
        {...props}
        filters={<AccountFilter accountType={filter.type} />}
        filter={filter}
        sort={{ field: "updatedAt", order: "DESC" }}
      >
        <Datagrid>
          {/*<AvatarShow />*/}
          <TextField source="id" />
          <TextField source="nom" label={translate("commons.name")} />
          <TextField source="prenom" label={translate("commons.firstName")} />
          <TextField source="username" label={translate("commons.username")} />
          {/*<ViewImage label={translate('commons.image')} source="avatar" containerId="avatar"/>*/}
          <EmailField source="email" label={translate("commons.email")} />
          <TextField source="accessTokenGmail" />
          <TextField source="refreshTokenGmail" />
          <TextField source="cookieForMeet" />
          <TextField source="telephone" />
          <TextField source="address" />
          <BooleanField source="isLoaded" />
          <DateField source="dateNaissance" />
          <TextField source="nConducteur" />
          <TextField source="paysRattachement" />
          <TextField source="etablissementId" />
          <TextField source="codeAnalytiqueId" />
          <TextField source="buId" />
          <TextField source="societeId" />
          <TextField source="codeSecurity" />
          <BooleanField source="isEnableNotification" />
          <BooleanField source="notiffrais" />
          <TextField source="raisonSociale" />
          <TextField source="avatar" />
          <DateField source="lastTimeOpenRh" />
          <TextField source="realm" />
          <TextField source="emailVerified" />
          <TextField
            source="telephone"
            label={translate("commons.serialNumber")}
          />
          <TextField source="type" label={translate("commons.type")} />
          <ReferenceManyField
            target="principalId"
            reference="RoleMappings"
            label={translate("commons.roles")}
          >
            <SingleFieldList>
              <ReferenceField source="roleId" reference="Roles" link={false}>
                <ChipField source="name" />
              </ReferenceField>
            </SingleFieldList>
          </ReferenceManyField>
          <BooleanField source="sorti" />
          <BooleanField source="verif" />
          <BooleanField source="verifmoteur" />
          <BooleanField source="veriftracte" />
          <BooleanField source="vieprivee" />
          {/*{filter.type === AccountType.CONDUCTEUR && (*/}
          {/*<HaveEyeIconField label={translate("commons.photos")} />*/}
          {/*)}*/}
          {/*{filter.type === AccountType.CONDUCTEUR && (*/}
          {/*<ShowContactField label={translate("commons.appelPersonnel")} />*/}
          {/*)}*/}
          <EditAccountButton />
          <DeleteButton undoable={false} />
          <PushNotificationButton />
        </Datagrid>
      </List>
    </div>
  );
};

export const AccountEdit = (props: any) => {
  const translate = useTranslate();
  const [type, setType] = useState<any>();
  useEffect(() => {
    const { type: post_id_string } = parse(props.location.search);
    setType(post_id_string);
  }, [props.location.search]);

  let choices: any = [];
  const validate = (values: any) => {
    const errors: any = {};
    let validatePassword =
      values.type && values.type === AccountType.ADMIN
        ? validatorPassword(values.password)
        : [];
    if (values.password && values.confirmPassword) {
      if (values.password !== values.confirmPassword) {
        errors.confirmPassword = translate("alert.confirmPasswordNotSame");
      }
      if (validatePassword.length > 0) {
        errors.password = validatePassword.map((item: any, index) => {
          return <p key={index}>{translate(`alert.${item.validation}`)}</p>;
        });
      }
    }
    return errors;
  };
  const validateEmail = email();
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mt-3">
        {/* <Link to="/Accounts" className="text-decoration-none">
          <ArrowBackIcon /> {translate("button.back")}
        </Link> */}
        <BackButton />
        <h3>{translate("account.editAccount")}</h3>
        <b></b>
      </div>
      <Edit {...props}>
        <TabbedForm validate={validate}>
          <FormTab label={translate("account.information")}>
            <TextInput disabled source="id" />
            <TextInput source="nom" label={translate("commons.name")} />
            <TextInput source="prenom" label={translate("commons.firstName")} />
            <TextInput source="username" />
            <TextInput source="address" />
            <TextInput source="raisonSociale" />
            <TextInput source="codeSecurity" />
            <DateField source="dateNaissance" />
            <BooleanInput source="notiffrais" />
            <TextInput
              source="email"
              type="email"
              validate={validateEmail}
              label={translate("commons.email")}
            />
            <TextInput
              source="telephone"
              label={translate("commons.serialNumber")}
            />
            {type === AccountType.ADMIN && (
              <PasswordInput
                source="password"
                label={translate("commons.password")}
              />
            )}
            {type === AccountType.ADMIN && (
              <PasswordInput
                source="confirmPassword"
                label={translate("commons.confirmPassword")}
              />
            )}
            <TextInput
              source="accessTokenGmail"
              label={translate("commons.accessTokenGmail")}
            />
            <TextInput
              source="refreshTokenGmail"
              label={translate("commons.refreshTokenGmail")}
            />
            <TextInput
              source="cookieForMeet"
              label={translate("commons.cookie")}
            />
            <TextInput
              source="type"
              disabled
              label={translate("commons.type")}
            />
            <BooleanInput source="sorti" />
            <BooleanInput source="verif" />
            <BooleanInput source="verifmoteur" />
            <BooleanInput source="veriftracte" />
            <BooleanInput source="vieprivee" />
          </FormTab>
          <FormTab path="roles" label={translate("commons.roles")}>
            <ReferenceManyField
              addLabel={false}
              target="principalId"
              reference="RoleMappings"
            >
              <Datagrid>
                <ReferenceField
                  source="roleId"
                  reference="Roles"
                  label={translate("commons.roles")}
                >
                  <TextField source="name" label={translate("commons.name")} />
                </ReferenceField>
                <EditRoleButton />
                <DeleteRoleButton />
              </Datagrid>
            </ReferenceManyField>
            <AddRoleButton />
          </FormTab>
        </TabbedForm>
      </Edit>
    </div>
  );
};

export const AccountCreate = (props: any) => {
  const translate = useTranslate();
  let choices: any = [];
  let currentSociete = 0;
  const validate = (values: any) => {
    const errors: any = {};
    let validatePassword =
      values.type && values.type === AccountType.ADMIN
        ? validatorPassword(values.password)
        : [];
    if (values.password && values.password !== values.confirmPassword) {
      errors.confirmPassword = translate("alert.confirmPasswordNotSame");
    }
    if (validatePassword.length > 0) {
      errors.password = validatePassword.map((item: any, index) => {
        return <p key={index}>{translate(`alert.${item.validation}`)}</p>;
      });
    }
    return errors;
  };
  const validateEmail = email();
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mt-3">
        {/* <Link to="/Accounts" className="text-decoration-none">
          <ArrowBackIcon /> {translate("button.back")}
        </Link> */}
        <BackButton />
        <h3>{translate("account.createAccount")}</h3>
        <b></b>
      </div>
      <Create {...props}>
        <SimpleForm redirect="/Accounts" validate={validate}>
          <TextInput source="nom" label={translate("commons.name")} />
          <TextInput source="prenom" label={translate("commons.firstName")} />
          <TextInput source="username" label={translate("commons.username")} />
          <TextInput source="address" />
          <TextInput source="raisonSociale" />
          <DateField source="codeSecurity" />
          <DateField source="dateNaissance" />
          <BooleanInput source="notiffrais" />
          <TextInput
            source="email"
            type="email"
            validate={validateEmail}
            label={translate("commons.email")}
          />
          <TextInput
            source="telephone"
            label={translate("commons.serialNumber")}
          />
          <TextInput source="password" className="d-none" />
          <TextInput
            source="accessTokenGmail"
            label={translate("commons.accessTokenGmail")}
          />
          <TextInput
            source="refreshTokenGmail"
            label={translate("commons.refreshTokenGmail")}
          />
          <TextInput
            source="cookieForMeet"
            label={translate("commons.cookie")}
          />
          <SelectInput
            source="type"
            choices={[
              { id: AccountType.ADMIN, name: AccountType.ADMIN },
              { id: AccountType.CONDUCTEUR, name: AccountType.CONDUCTEUR },
            ]}
          />
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              return formData.type === AccountType.ADMIN ? (
                <div>
                  <PasswordInput
                    source="password"
                    className="d-block"
                    label={translate("commons.password")}
                  />
                  <PasswordInput
                    source="confirmPassword"
                    label={translate("commons.confirmPassword")}
                  />
                </div>
              ) : (
                <div>
                  <PasswordInput source="password" className="d-none" />
                  <PasswordInput source="confirmPassword" className="d-none" />
                </div>
              );
            }}
          </FormDataConsumer>
        </SimpleForm>
      </Create>
    </div>
  );
};
