import React, { useState } from "react";
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DateField,
  DateInput,
  DeleteButton,
  Edit,
  EditButton,
  Filter,
  ImageField,
  ImageInput,
  List,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useTranslate,
} from "react-admin";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { AccountType } from "../react-admin-loopback/api/shared-constants";
import { LoopbackAPI } from "../react-admin-loopback/api/loopback-api";
import { API_URL } from "../variables/constant";
import ClearIcon from "@material-ui/icons/Clear";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import { makeStyles } from "@material-ui/core/styles";
import { ViewImage } from "./view-img-verify";
import BackButton from "../components/BackButton";

const VerifyFilter = (props: any) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <ReferenceInput
        source="accountId"
        perPage= { null }
        sort={{ field: "nom", order: "ASC" }}
        reference="Accounts"
        alwaysOn
        filter={{ type: AccountType.CONDUCTEUR }}
      >
        <SelectInput optionText="nom" />
        {/*<AutocompleteInput optionText="nom"/>*/}
      </ReferenceInput>
    </Filter>
  );
};
const HaveEyeIconField = (props) => {
  const useStyles = makeStyles({
    iconEyes: {
      color: "rgb(204, 204, 0)",
      fontSize: 32,
    },
  });
  const classes = useStyles();
  return props?.record?.images?.length > 0 ? (
    <VisibilityOutlinedIcon className={classes.iconEyes} />
  ) : (
    <span>-</span>
  );
};

const ImagesShow = (props) => {
  const [listImages, setListImage] = useState(props.images);
  const removeImage = async (index) => {
    const API = new LoopbackAPI();
    listImages.splice(index, 1);
    setListImage([...listImages]);
    // await API.assurances.assurancePrototypePatchAttributes(props.id, {images: [...listImages]});
  };
  return listImages?.length > 0 ? (
    <div className="d-flex wrap-list-image overflow-auto">
      {listImages.map((item, index) => {
        return (
          <div className="wrap-image" key={"image" + index}>
            <img
              src={`${API_URL}/Containers/photos/download/${item}`}
              alt=""
              className="mr-2 wrap-image"
            />
            <div className="ic-cancel" onClick={() => removeImage(index)}>
              <ClearIcon />
            </div>
          </div>
        );
      })}
    </div>
  ) : null;
};

const ViewFormulaire = (props) => {
  const useStyles = makeStyles({
    iconEyes: {
      color: "rgb(204, 204, 0)",
      fontSize: 32,
    },
  });
  const classes = useStyles();
  return (
    <Link
      to={`/formulaire?assuranceId=${props.record.id}`}
      onClick={(e) => e.stopPropagation()}
      target="_blank"
    >
      <VisibilityOutlinedIcon className={classes.iconEyes} />
    </Link>
  );
};

export const CustomForm = (props) => {
  const translate = useTranslate();
  return (
    <div className="w-100">
      <div className="d-flex flex-wrap">
        <div className="col-12 col-sm-6">
          <ReferenceInput
            source="accountId"
            reference="Accounts"
            perPage = { null }
            sort={{ field: "nom", order: "ASC" }}
            label={translate("commons.driver")}
            filter={{ type: AccountType.CONDUCTEUR }}
            fullWidth
          >
            <SelectInput optionText="nom" />
          </ReferenceInput>
        </div>
        <div className="col-12 col-sm-6">
          <TextInput source="parcMoteur" fullWidth />
        </div>
        <div className="col-12 col-sm-6">
          <TextInput source="parcTracte" fullWidth />
        </div>
        <div className="col-12 col-sm-6">
          <TextInput source="anomalieCommentaire" fullWidth />
        </div>
        <div className="col-12">
          <ImagesShow image={props?.record?.image} id={props?.record?.id} />
          <ImageInput
            source="pictures"
            label={translate("commons.uploadImage")}
            accept="image/*"
          >
            <ImageField source="src" title="image" />
          </ImageInput>
        </div>
        <div className="col-12 col-sm-6">
          <TextInput source="anomalieIm1" fullWidth />
        </div>
        <div className="col-12 col-sm-6">
          <TextInput source="anomalieIm2" fullWidth />
        </div>
        <div className="col-12 col-sm-6">
          <TextInput source="anomalieIm3" fullWidth />
        </div>
        <div className="col-12 col-sm-6">
          <BooleanInput source="OK" />
        </div>
        <div className="col-12 col-sm-6">
          <TextInput source="etape" fullWidth />
        </div>
      </div>
    </div>
  );
};
export const VerifyList = (props) => {
  const translate = useTranslate();
  {
    console.log(props.record);
  }
  return (
    <List
      {...props}
      filters={<VerifyFilter />}
      exporter={false}
      sort={{ field: "id", order: "DESC" }}
    >
      <Datagrid>
        <TextField source="id" />
        <ReferenceField source="accountId" reference="Accounts">
          <TextField source="nom" />
        </ReferenceField>
        <TextField source="parcMoteur" />
        <TextField source="parcTracte" />
        <TextField source="anomalieCommentaire" />
        <ViewImage source="anomalieIm1" containerId="verifications" />
        <ViewImage source="anomalieIm2" containerId="verifications" />
        <ViewImage source="anomalieIm3" containerId="verifications" />
        {/*<TextField source="anomalieIm1"/>*/}
        {/*<ViewFormulaire label={translate('commons.viewForm')}/>*/}
        {/*<TextField source="anomalieIm2"/>*/}
        {/*<TextField source="anomalieIm3"/>*/}
        <BooleanField source="OK" />
        <TextField source="etape" />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" />
        {/*<EditButton/>*/}
        {/*<DeleteButton/>*/}
      </Datagrid>
    </List>
  );
};

export const VerifyCreate = (props: any) => {
  const translate = useTranslate();
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mt-3">
        {/* <Link to="/Moteurs" className="text-decoration-none">
          <ArrowBackIcon /> {translate("button.back")}
        </Link> */}
        <BackButton/>
        <h3>{translate("missions.create")}</h3>
        <b></b>
      </div>
      <Create {...props}>
        <SimpleForm redirect="/Moteurs">
          <CustomForm />
        </SimpleForm>
      </Create>
    </div>
  );
};

export const VerifyEdit = (props: any) => {
  const translate = useTranslate();
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mt-3">
        {/* <Link to="/Moteurs" className="text-decoration-none">
          <ArrowBackIcon /> {translate("button.back")}
        </Link> */}
        <BackButton/>
        <h3>{translate("missions.edit")}</h3>
        <b></b>
      </div>
      <Edit {...props}>
        <SimpleForm>
          <CustomForm />
        </SimpleForm>
      </Edit>
    </div>
  );
};
