import React, { useState } from 'react';
import {
    Create,
    Datagrid,
    DateField, DeleteButton,
    Edit, EditButton, FileField, FileInput, Filter, ImageField, ImageInput,
    List,
    ReferenceField, ReferenceInput, SelectInput,
    SimpleForm,
    TextField, TextInput,
    translate,
    useTranslate
} from 'react-admin';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { AccountType } from '../react-admin-loopback/api/shared-constants';
import { API_URL } from '../variables/constant';
import { LoopbackAPI } from '../react-admin-loopback/api/loopback-api';
import ClearIcon from '@material-ui/icons/Clear';
import { ViewImage } from './view-image';
import BackButton from '../components/BackButton';

const ImagesShow = (props) => {
    const [image, setImage] = useState(props.image);
    const removeImage = async () => {
        const API = new LoopbackAPI();
        setImage(null);
        console.log(image);
        // await API.dNs.dnPrototypePatchAttributes(props.id, {image: ''});
    };
    return (
        image?.length > 0 ? (
            <div className="d-flex align-items-center justify-content-center">
                <div className="wrap-image">
                    <img src={`${API_URL}/Containers/photos/download/${props?.image}`} alt="" className="mr-2 custom-image"/>
                    <div className="ic-cancel" onClick={() => removeImage()}>
                        <ClearIcon/>
                    </div>
                </div>
            </div>
        ) : null
    );
};
export const GuideList = props => {
    const translate = useTranslate();
    return (
        <List {...props}
              filter={{ guide: { "neq": null } }}
              sort={{ field: "updatedAt", order: "DESC" }}>
            <Datagrid>
                <FileView guide="guide"/>
                <DeleteButton />
            </Datagrid>
        </List>
    )
};

const FileView = (props) => {
    return (
        <div>
        <a href={`${API_URL}/Containers/pdf/download/${props.record.guide}`}>{props.record.guide}</a>
        </div>
    )
}

const ImagesShowInTable = (props) => {
    return props?.record?.image?.length > 0 ? (
        <div className="d-flex align-items-center justify-content-center">
            <img src={`${API_URL}/Containers/photos/download/${props?.record?.image}`} alt="" className="custom-image"/>
        </div>
    ): null;
};


const CustomForm = (props) => {
    const translate = useTranslate();
    return (
        <div className="w-100">
            <div className="d-flex flex-wrap">
                <div className="col-12">
                    {/*<ImagesShow image={props?.record?.image} id={props?.record?.id}/>*/}
                    {/*<ImageInput source="pictures" label={translate('commons.uploadImage')} accept="image/*">*/}
                    {/*    <ImageField source="src" title="image" />*/}
                    {/*</ImageInput>*/}
                    <FileInput source="guide" label="Related files" accept="application/pdf">
                        <FileField source="guide" title="guide" />
                    </FileInput>
                </div>
            </div>
        </div>
    )
};

export const GuideCreate = (props: any) => {
    const translate = useTranslate();
    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mt-3">
                {/* <Link to="/Parametres" className="text-decoration-none">
                    <ArrowBackIcon/> {translate('button.back')}
                </Link> */}
                <BackButton/>
                <h3>{translate('guide.create')}</h3>
                <b></b>
            </div>
            <Create {...props}>
                <SimpleForm redirect="/Parametres">
                    <CustomForm/>
                </SimpleForm>
            </Create>
        </div>
    );
};

