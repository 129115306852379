import React, { cloneElement, useState } from 'react';
import {
    Create,
    Datagrid,
    DateField, DateInput, DeleteButton,
    Edit, EditButton, FileField, FileInput, Filter, ImageField, ImageInput,
    List,
    ReferenceField, ReferenceInput, sanitizeListRestProps, SelectInput,
    SimpleForm,
    TextField, TextInput, TopToolbar,
    translate, useDataProvider, useListContext, useRefresh,
    useTranslate
} from 'react-admin';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { AccountType } from '../react-admin-loopback/api/shared-constants';
import { API_URL } from '../variables/constant';
import { LoopbackAPI } from '../react-admin-loopback/api/loopback-api';
import ClearIcon from '@material-ui/icons/Clear';
import { ViewImage } from './view-image';
import { useNotify, useRedirect } from 'ra-core';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { CreateButton } from 'react-admin';
import CircularProgress from '@material-ui/core/CircularProgress';
export const InstallationList = props => {
    const translate = useTranslate();

    return (
        <List {...props}
              sort={{ field: "modified", order: "DESC" }}>
            <Datagrid>
                <TextField source="id"/>
                <TextField source="userId"/>
                <DateField source="modified"
                           showTime/>
                <TextField source="deviceType"/>
                <TextField source="deviceToken"/>
                <TextField source="appVersion"/>
            </Datagrid>
        </List>
    )
};


const CustomSendNotifyButton = (props) => {
    const [show, setShow] = useState(false);
    const notify = useNotify();
    const sendNotify = async () => {

        const API = new LoopbackAPI();
        try {
            await API.appVersions.appVersionRemindNewVersion();
            notify(
                'Success',
                'success'
            );
        } catch (error) {
            notify(
                typeof error === 'string'
                    ? error
                    : typeof error === 'undefined' || !error.message
                        ? 'ra.auth.sign_in_error'
                        : error.message,
                'warning'
            );
        }
        // dataProvides.delete('Etapes', {id:  props.record.id})
        //     .then(({data}) => {
        //         console.log(data);
        //         setLoading(false);
        //         setShow(false);
        //         refresh();
        //     }).catch((e) => {
        //     setLoading(false);
        // });
    }
    return (
        <Button
            onClick={() => sendNotify()}
            color="primary"
            startIcon={<NotificationsIcon />}
        >
        </Button>
    );
};


const CustomForm = (props) => {
    const translate = useTranslate();
    return (
        <div className="w-100">
            <div className="d-flex flex-wrap">
                <div className="col-12 col-sm-6">
                    <TextInput source="version" fullWidth />
                </div>
                <div className="col-12 col-sm-6">
                    <TextInput source="buildNumber" fullWidth />
                </div>
            </div>
        </div>
    )
};

export const AppVersionCreate = (props: any) => {
    const translate = useTranslate();
    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mt-3">
                <Link to="/AppVersions" className="text-decoration-none">
                    <ArrowBackIcon/> {translate('button.back')}
                </Link>
                <h3>{translate('appversion.create')}</h3>
                <b></b>
            </div>
            <Create {...props}>
                <SimpleForm redirect="/AppVersions">
                    <CustomForm/>
                </SimpleForm>
            </Create>
        </div>
    );
};

export const AppVersionEdit = (props: any) => {
    const translate = useTranslate();
    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mt-3">
                <Link to="/Parametres" className="text-decoration-none">
                    <ArrowBackIcon/> {translate('button.back')}
                </Link>
                <h3>{translate('guide.edit')}</h3>
                <b></b>
            </div>
            <Edit {...props}>
                <SimpleForm>
                    <CustomForm/>
                </SimpleForm>
            </Edit>
        </div>
    );
};

