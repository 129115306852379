export enum ContactType {
  SOS = 'SOS',
  REPERTORIE = 'REPERTORIE',
  APPELPERSONNEL = 'APPELPERSONNEL',
  ASSURANCE = 'ASSURANCE',
  EXPLOITANT = 'EXPLOITANT',
  HOTLINE = 'HOTLINE'
}

export enum MediaType {
  FILM = 'FILM',
  MUSIQUE = 'MUSIQUE'
}

export enum Modele {
  PROBLEMROAD = 'Problème route',
  DELIVERYMADE = 'Livraison effectuée'

}

export enum AccountType {
  CONDUCTEUR = 'CONDUCTEUR',
  ADMIN = 'ADMIN',
  PERSONNEL = 'PERSONNEL',
  EXTERNE = 'EXTERNE'
}

export enum LieuType {
  TRACTEUR = 'TRACTEUR',
  REMORQUE = 'REMORQUE',
  PORTEUR = 'PORTEUR'
}

export const GPS_APP_ID = 'GPS_APP_ID';
export const TRANSLALLIANCE_APP_ID = 'TRANSLALLIANCE_APP_ID';
export const EMAIL_CRED = {
  host: 'host',
  username: 'test',
  password: 'test'
};

export enum EtapePay {
  LU = 'LU',
  FR = 'FR',
  DE = 'DE'
}

export enum HorairesActivity {
  REPOS = 'Repos',
  CONDUITE = 'Conduite',
  TRAVAIL = 'Travail',
}

export enum Unit {
  PALETTE = 'Palette',
  METRE = 'Mètre',
  POIDS = 'Poids'
}

export enum EtapeAction {
  CHG_ARR = 'CHG_ARR',
  CHG_DEB = 'CHG_DEB',
  CHG_FIN = 'CHG_FIN',
  LIV_ARR = 'LIV_ARR',
  LIV_DEB = 'LIV_DEB',
  LIV_FIN = 'LIV_FIN'
}

export enum Language {
  EN = 'en',
  FR = 'fr'
}

export enum ParameterType {
  GPS_APP_ID = 'GPS_APP_ID',
  TRANSLALLIANCE_APP_ID = 'TRANSLALLIANCE_APP_ID',
  LIMIT_APPEL_CALL = 'LIMIT_APPEL_CALL',
  FORMULAIRE_APP_ID = 'FORMULAIRE_APP_ID',
  ACCESS_TOKEN = 'ACCESS_TOKEN',
  LAST_VERSION = 'LAST_VERSION'
}

export enum HorairesType {
  REPOS = 'Repos',
  CONDUITE = 'Conduite',
  TRAVAIL = 'Travail',
  DISPO = 'Dispo'
}

export enum EtapeType {
  CHARGEMENT = 'CHARGEMENT',
  ROULAGE = 'ROULAGE',
  LIVRAISON = 'LIVRAISON'
}

export enum NotificationType {
  WARNING_WRONG_TRACTUER = 'WARNING_WRONG_TRACTUER',
  NEW_MISSION_INFO = 'NEW_MISSION_INFO',
  ALERT_OVER_DRIVING_TIME = 'ALERT_OVER_DRIVING_TIME',
  WARNING_ACCOUNT_REMORQUE_CHANGE = 'WARNING_ACCOUNT_REMORQUE_CHANGE',
  WARNING_MISSION_REMORQUE_CHANGE = 'WARNING_MISSION_REMORQUE_CHANGE',
  PAUSE_BREAK = 'PAUSE_BREAK'
}

export enum NotificationAction {
  RECTIFIE = 'RECTIFIE',
  CONFIRM = 'CONFIRM',
  OK = 'OK ',
}
