import { makeStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import RefreshOutlinedIcon from '@material-ui/icons/RefreshOutlined'
import * as React from 'react';
import { forwardRef } from 'react';
import { AppBar, MenuItemLink, UserMenu, useTranslate } from 'react-admin';
import src from "../images/logo.png";

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});

const ConfigurationMenu = forwardRef<any, any>((props, ref) => {
    // const translate = useTranslate();
    return (
        <MenuItemLink
            ref={ref}
            to="/configuration"
            primaryText={"Réglages"}
            leftIcon={<SettingsIcon />}
        />
    );
});


const ChangePasswordMenu = forwardRef<any, any>((props, ref) => {
    const translate = useTranslate();
    return (
        <MenuItemLink
            ref={ref}
            to="/change-password"
            primaryText={translate('password.changePassword')}
            leftIcon={<RefreshOutlinedIcon />}
            onClick={props.onClick}
        />
    );
});

const CustomUserMenu = (props: any) => (
    <UserMenu {...props}>
        <ConfigurationMenu />
        <ChangePasswordMenu />
    </UserMenu>
);

const CustomAppBar = (props: any) => {
    const classes = useStyles();
        
    return (
       <>
        
        <AppBar color="default" {...props} userMenu={<CustomUserMenu />}>
            {/* <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            /> */}
           <b>Connect BackOffice</b>
            <span className={classes.spacer} />
            <img className="logo" src={src} alt=""/>
        </AppBar>
       </>
    );
};

export default CustomAppBar;
