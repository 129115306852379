import {
  Button,
  Create,
  Datagrid,
  DateField,
  DeleteButton,
  Edit,
  EmailField,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  translate,
  useTranslate,
} from "react-admin";
import React, { Component, useState } from "react";
import { parse } from "query-string";
import {
  AccountType,
  ContactType,
} from "../react-admin-loopback/api/shared-constants";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Divider, Tab, Tabs } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import BackButton from "../components/BackButton";

const tabs = Object.keys(ContactType).map((it) => {
  return { id: it, name: ContactType[it] };
});

const ContactFilter = (props: any) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <TextInput source="nom" alwaysOn label={translate("commons.name")} />
      <TextInput
        source="prenom"
        alwaysOn
        label={translate("commons.firstName")}
      />
      <TextInput source="email" alwaysOn label={translate("commons.email")} />
      {props.type !== ContactType.REPERTORIE &&
        props.type !== ContactType.APPELPERSONNEL && (
          <ReferenceInput
            source="societeId"
            reference="Societes"
            label={translate("commons.societe")}
            alwaysOn
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        )}
      {props.type === ContactType.REPERTORIE && (
        <ReferenceInput
          source="serviceId"
          reference="Services"
          label={translate("commons.service")}
          alwaysOn
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      )}
      {props.type === ContactType.APPELPERSONNEL && (
        <ReferenceInput
          source="accountId"
          reference="Accounts"
          sort={{ field: "nom", order: "ASC" }}
          label={translate("commons.driver")}
          alwaysOn
        >
          <SelectInput optionText="nom" />
        </ReferenceInput>
      )}
    </Filter>
  );
};

const EditContactButton = ({ classes, record }: any) => {
  const translate = useTranslate();
  return (
    <Button
      component={Link}
      to={`/Contacts/${record.id}?type=${record.type}`}
      label={translate("commons.edit")}
      title="Edit"
    >
      <EditIcon />
    </Button>
  );
};

export interface SampleFilter {
  type: string;
  societeId?: number;
  accountId?: number;
  serviceId?: number;
}
export const ContactList = (props) => {
  const translate = useTranslate();
  const { type: post_id_string } = parse(props.location.search);
  const { relationId: post_id_string1 } = parse(props.location.search);
  const type = post_id_string as string;
  const relationId = post_id_string1
    ? parseInt(post_id_string1 as string, 10)
    : 0;
  const sampleFilter: SampleFilter = {
    type: type ? type : ContactType.SOS,
  };
  if (relationId > 0) {
    switch (type) {
      case ContactType.SOS:
        sampleFilter.societeId = relationId;
        break;
      case ContactType.REPERTORIE:
        sampleFilter.serviceId = relationId;
        break;
      case ContactType.APPELPERSONNEL:
        sampleFilter.accountId = relationId;
        break;
    }
  }
  const [filter, setFilter] = useState(sampleFilter);
  const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
    setFilter({ ...sampleFilter, type: value });
  };
  return (
    <div className="mt-4">
      <Tabs
        variant="fullWidth"
        centered
        value={filter.type}
        indicatorColor="primary"
        onChange={handleChange}
      >
        {tabs.map((choice) => (
          <Tab
            key={choice.id}
            label={translate(choice.name)}
            value={choice.id}
          />
        ))}
      </Tabs>
      <Divider />
      <List
        {...props}
        filters={<ContactFilter type={filter.type} />}
        filter={filter}
        sort={{ field: "updatedAt", order: "DESC" }}
      >
        <Datagrid>
          <TextField source="nom" label={translate("commons.name")} />
          <TextField source="prenom" label={translate("commons.firstName")} />
          <EmailField source="email" label={translate("commons.email")} />
          <TextField source="poste" label={translate("commons.post")} />
          {filter.type !== ContactType.REPERTORIE &&
            filter.type !== ContactType.APPELPERSONNEL && (
              <ReferenceField
                source="societeId"
                reference="Societes"
                label={translate("commons.societe")}
              >
                <TextField source="name" />
              </ReferenceField>
            )}
          {filter.type === ContactType.REPERTORIE && (
            <ReferenceField
              source="serviceId"
              reference="Services"
              label={translate("commons.service")}
            >
              <TextField source="name" />
            </ReferenceField>
          )}
          {filter.type === ContactType.APPELPERSONNEL && (
            <ReferenceField
              source="accountId"
              reference="Accounts"
              sort={{ field: "nom", order: "ASC" }}
              label={translate("commons.driver")}
            >
              <TextField source="nom" />
            </ReferenceField>
          )}
          {filter.type !== ContactType.REPERTORIE && (
            <TextField
              source="hangouts"
              label={translate("commons.contactLink")}
            />
          )}
          <DateField
            source="createdAt"
            label={translate("commons.createdAt")}
          />
          <DateField
            source="updatedAt"
            label={translate("commons.updatedAt")}
          />
          <EditContactButton />
          <DeleteButton undoable={false} />
        </Datagrid>
      </List>
    </div>
  );
};

export const ContactEdit = (props) => {
  const translate = useTranslate();
  const { type: post_id_string } = parse(props.location.search);
  const [state, setState] = useState({ contactType: post_id_string });
  const contactType = Object.keys(ContactType).map((it) => {
    return { id: it, name: ContactType[it] };
  });
  const handleChange = (event: any, value: any) => {
    setState({ contactType: event.target.value });
  };
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mt-3">
        {/* <Link to="/Contacts" className="text-decoration-none">
          <ArrowBackIcon /> {translate("button.back")}
        </Link> */}
        <BackButton/>
        <h3>{translate("contact.editContact")}</h3>
        <b></b>
      </div>
      <Edit {...props}>
        <SimpleForm>
          <TextInput source="nom" label={translate("commons.name")} />
          <TextInput source="prenom" label={translate("commons.firstName")} />
          <TextInput source="email" label={translate("commons.email")} />
          <TextInput source="poste" label={translate("commons.post")} />
          <SelectInput
            label={translate("commons.type")}
            onChange={handleChange}
            source="type"
            choices={contactType}
          />
          {state.contactType !== ContactType.REPERTORIE &&
            state.contactType !== ContactType.APPELPERSONNEL && (
              <ReferenceInput
                source="societeId"
                reference="Societes"
                label={translate("commons.societe")}
              >
                <SelectInput source="name" />
              </ReferenceInput>
            )}
          {state.contactType === ContactType.REPERTORIE && (
            <ReferenceInput
              source="serviceId"
              reference="Services"
              label={translate("commons.service")}
            >
              <SelectInput source="name" />
            </ReferenceInput>
          )}
          {state.contactType === ContactType.APPELPERSONNEL && (
            <ReferenceInput
              source="accountId"
              reference="Accounts"
              sort={{ field: "nom", order: "ASC" }}
              filter={{ type: AccountType.CONDUCTEUR }}
              label={translate("commons.driver")}
            >
              <SelectInput optionText="nom" />
            </ReferenceInput>
          )}
          {state.contactType !== ContactType.REPERTORIE && (
            <TextInput
              source="hangouts"
              label={translate("commons.contactLink")}
            />
          )}
        </SimpleForm>
      </Edit>
    </div>
  );
};

export const ContactCreate = (props) => {
  const translate = useTranslate();
  const [state, setState] = useState({ contactType: "" });
  const contactType = Object.keys(ContactType).map((it) => {
    return { id: it, name: ContactType[it] };
  });
  const handleChange = (event: any, value: any) => {
    setState({ contactType: event.target.value });
  };
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mt-3">
        {/* <Link to="/Contacts" className="text-decoration-none">
          <ArrowBackIcon /> {translate("button.back")}
        </Link> */}
        <BackButton/>
        <h3>{translate("contact.createContact")}</h3>
        <b></b>
      </div>
      <Create {...props}>
        <SimpleForm redirect="/Contacts">
          <TextInput source="nom" label={translate("commons.name")} />
          <TextInput source="prenom" label={translate("commons.firstName")} />
          <TextInput source="email" label={translate("commons.email")} />
          <TextInput source="poste" label={translate("commons.post")} />
          <SelectInput
            label={translate("commons.type")}
            onChange={handleChange}
            source="type"
            choices={contactType}
          />
          {state.contactType !== ContactType.REPERTORIE &&
            state.contactType !== ContactType.APPELPERSONNEL && (
              <ReferenceInput
                source="societeId"
                reference="Societes"
                label={translate("commons.societe")}
              >
                <SelectInput source="name" />
              </ReferenceInput>
            )}
          {state.contactType === ContactType.REPERTORIE && (
            <ReferenceInput
              source="serviceId"
              reference="Services"
              label={translate("commons.service")}
            >
              <SelectInput source="name" />
            </ReferenceInput>
          )}
          {state.contactType === ContactType.APPELPERSONNEL && (
            <ReferenceInput
              source="accountId"
              reference="Accounts"
              sort={{ field: "nom", order: "ASC" }}
              filter={{ type: AccountType.CONDUCTEUR }}
              label={translate("commons.driver")}
            >
              <SelectInput optionText="nom" />
            </ReferenceInput>
          )}
          {state.contactType !== ContactType.REPERTORIE && (
            <TextInput
              source="hangouts"
              label={translate("commons.contactLink")}
            />
          )}
        </SimpleForm>
      </Create>
    </div>
  );
};
