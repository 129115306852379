import React from "react";
import {
  BooleanField,
  Datagrid,
  DateField,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  useTranslate,
} from "react-admin";

const HistoriViePriveeFilter = (props: any) => {
  const translate = useTranslate();

  return (
    <Filter {...props}>
      <ReferenceInput
        source="accountId"
        perPage= { null }
        label={translate("commons.name")}
        sort={{ field: "nom", order: "ASC" }}
        reference="Accounts"
        alwaysOn
      >
        <SelectInput optionText="nom" />
      </ReferenceInput>
    </Filter>
  );
};

export const getHistoryViePrivee = (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const translate = useTranslate();

  return (
    <List {...props} exporter={false} sort={{ field: "id", order: "DESC" }} filters={<HistoriViePriveeFilter />}>
      <Datagrid>
        <TextField source="id" />
        <DateField source="switchDate" showTime />
        <BooleanField source="etat" />
        <ReferenceField
          source="accountId"
          reference="Accounts"
          label={translate("commons.driver")}
        >
          <TextField source="nom" />
        </ReferenceField>
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
      </Datagrid>
    </List>
  );
};
