import {CircularProgress} from '@material-ui/core';
import {createMuiTheme} from "@material-ui/core/styles";
import {ThemeProvider} from "@material-ui/styles";
import Viewer, {Worker} from '@phuocng/react-pdf-viewer';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {LoopbackAPI} from '../react-admin-loopback/api/loopback-api';
import {API_URL} from '../variables/constant';
import "./formulaire.scss";

export interface DataFormulaire {
    nom: string;
    prenom: string;
    engineRegistration: string;
    registrationVehicle: string;
    date: string;
    locality: string;
    typeOfLocation: string;
    isSigned: boolean;
    describeTheCircumstances: string;
    personalInjury: boolean;
    describe: string;
    signatureOfDriver: any;
    signatureOfOperator: any;
    damages: {
        left: any;
        right: any;
    };

}

const Formulaire = (props) => {

    const search = useLocation().search;
    const assuranceId = new URLSearchParams(search).get("assuranceId");
    const [pdfName, setPdfName] = useState('');
    const [loading, setLoading] = useState(false);
    const searchPDF = async () => {
        setLoading(true)
        const API = new LoopbackAPI();
        if (assuranceId) {
            API.assurances.assuranceGenPdf({id: assuranceId?.toString()})
                .then((data: string) => new Promise<string>(resolve => setTimeout(() => resolve(data), 2000))) // delay 2s for sure pdf available on server
                .then((data: string) => {
                    setPdfName(data)
                    setLoading(false)
                }).catch(error => {
                    setLoading(false)
                    console.log(error)
                }
            )
        }
    }
    useEffect(() => {
        searchPDF()
    }, [assuranceId])

    return (
        <div className="wrap-content">
            {loading ? <div>
                <CircularProgress size={25} thickness={2}/>
            </div> : <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js">
                <div style={{height: '100%'}}>
                    <Viewer fileUrl={`${API_URL}/Containers/assurances/download/${pdfName}`}/>
                </div>
            </Worker>}

        </div>
    )
};
Formulaire.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
export const FormulairePage = (props: any) => (
    <ThemeProvider theme={createMuiTheme({})}>
        <Formulaire {...props} />
    </ThemeProvider>
);
