import React, {useState} from "react";
import {
    Create,
    Datagrid,
    DateField,
    Edit,
    Filter,
    ImageField,
    ImageInput,
    List,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
    useTranslate,
} from "react-admin";
import {API_URL} from "../variables/constant";
import {makeStyles} from "@material-ui/core/styles";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import ClearIcon from "@material-ui/icons/Clear";
import {LoopbackAPI} from "../react-admin-loopback/api/loopback-api";
import {ViewImage} from "./view-image";
import BackButton from "../components/BackButton";

const ReseveFilter = (props: any) => {
    const translate = useTranslate();
    return (
        <Filter {...props}>
            <ReferenceInput
                source="accountId"
                perPage= { null }
                sort={{field: "nom", order: "ASC"}}
                reference="Accounts"
                label={translate("commons.driver")}
                alwaysOn
            >
                <SelectInput optionText="nom"/>
            </ReferenceInput>
            <NumberInput source="etapeId" alwaysOn/>

            {/* <ReferenceInput source="etapeId" reference="Etapes" alwaysOn>
        <SelectInput optionText="id" />
      </ReferenceInput> */}
        </Filter>
    );
};

const ImagesShowInTable = (props) => {
    return props.record.image && props.record.image.length > 0 ? (
        <div className="d-flex align-items-center justify-content-center">
            <img
                src={`${API_URL}/Containers/reserves/download/${props?.record?.image}`}
                alt=""
                className="mr-2 w-100"
            />
        </div>
    ) : null;
};

// const ViewImage = (props) => {
//     const useStyles = makeStyles({
//         iconEyes: {
//             color: 'rgb(204, 204, 0)',
//             fontSize: 32
//         }
//     });
//     const classes = useStyles();
//     return (
//         <VisibilityOutlinedIcon className={classes.iconEyes} onClick={(e) => {
//
//         }}/>
//     )
// }

const ImagesShow = (props) => {
    const [listImages, setListImage] = useState(props.images);
    const removeImage = async (index) => {
        const API = new LoopbackAPI();
        listImages.splice(index, 1);
        setListImage([...listImages]);
        await API.reserves.reservePrototypePatchAttributes(props.id, {
            image: [...listImages],
        });
    };
    return listImages?.length > 0 ? (
        <div className="d-flex wrap-list-image overflow-auto">
            {listImages.map((item, index) => {
                return (
                    <div className="wrap-image" key={"image" + index}>
                        <img
                            src={`${API_URL}/Containers/reserves/download/${item}`}
                            alt=""
                            className="mr-2 wrap-image"
                        />
                        <div className="ic-cancel" onClick={() => removeImage(index)}>
                            <ClearIcon/>
                        </div>
                    </div>
                );
            })}
        </div>
    ) : null;
};

const CustomShowData = (props) => {
    const translate = useTranslate();
    try {
        const data = JSON.parse(props.record.tripInformation);
        const dataNeedView =
            "Nom: " +
            data.sie.nom +
            "," +
            "Moteur: " +
            data.moteur +
            ", Ot: " +
            data.ot +
            ", Chargements: " +
            data.tab_chargements[0].adresse +
            ", Livraisons: " +
            data.tab_livraisons[0].adresse;
        console.log(dataNeedView);
        return <span className={"limit-text"}>{JSON.stringify(dataNeedView)}</span>;
    } catch (e) {
        return (
            <span className={"limit-text"}>{translate("commons.dataNotValid")}</span>
        );
    }
};

const CustomReseveForm = (props) => {
    const translate = useTranslate();
    return (
        <div className="d-flex flex-wrap">
            {props?.isEdit && (
                <div className="col-12">
                    <ImagesShow images={props?.record?.image} id={props?.record?.id}/>
                </div>
            )}
            <div className="col-12">
                <ImageInput
                    source="pictures"
                    label={translate("commons.uploadImage")}
                    accept="image/*"
                    multiple={true}
                >
                    <ImageField source="src" title="image"/>
                </ImageInput>
            </div>
            <div className="col-12 col-sm-6">
                <NumberInput
                    source="tripId"
                    label={translate("commons.tripId")}
                    fullWidth
                />
            </div>
            <div className="col-12 col-sm-6">
                <ReferenceInput
                    source="accountId"
                    reference="Accounts"
                    sort={{field: "nom", order: "ASC"}}
                    label={translate("commons.driver")}
                    fullWidth
                >
                    <SelectInput optionText="nom"/>
                </ReferenceInput>
                {/*<NumberInput source="conducteurId" label={translate('commons.conducteurId')} fullWidth/>*/}
            </div>
            <div className="col-12 col-sm-6">
                <ReferenceInput source="etapeId" reference="Etapes" fullWidth>
                    <SelectInput optionText="etapeId"/>
                </ReferenceInput>
            </div>
            <div className="col-12 col-sm-6">
                <TextInput
                    source="latitude"
                    label={translate("commons.latitude")}
                    fullWidth
                />
            </div>
            <div className="col-12 col-sm-6">
                <TextInput
                    source="longitude"
                    label={translate("commons.longitude")}
                    fullWidth
                />
            </div>
            <div className="col-12 col-sm-6">
                <TextInput source="hdop" label={translate("commons.hdop")} fullWidth/>
            </div>
            <div className="col-12 col-sm-6">
                <TextInput
                    source="altitude"
                    label={translate("commons.altitude")}
                    fullWidth
                />
            </div>
            <div className="col-12 col-sm-6">
                <TextInput
                    source="speed"
                    label={translate("commons.speed")}
                    fullWidth
                />
            </div>
            <div className="col-12 col-sm-6">
                <TextInput
                    multiline
                    source="note"
                    label={translate("commons.note")}
                    fullWidth
                />
            </div>
            <div className="col-12 col-sm-6">
                <TextInput
                    multiline
                    source="tripInformation"
                    label={translate("commons.informationTrip")}
                    fullWidth
                />
            </div>
        </div>
    );
};

const HaveEyeIconField = (props) => {
    const useStyles = makeStyles({
        iconEyes: {
            color: "rgb(204, 204, 0)",
            fontSize: 32,
        },
    });
    const classes = useStyles();
    return <VisibilityOutlinedIcon className={classes.iconEyes}/>;
};

const CustomLatLng = (props) => {
    const value = props?.record[props?.source];
    return value?.length > 0 ? (
        <span>{(+value).toFixed(8)}</span>
    ) : (
        <span>-</span>
    );
};

export const ReseveList = (props) => {
    const translate = useTranslate();
    return (
        <List
            {...props}
            filters={<ReseveFilter/>}
            sort={{field: "updatedAt", order: "DESC"}}
        >
            <Datagrid>
                <TextField
                    source="id"
                    headerClassName="p-sm w-5"
                    cellClassName={"p-sm"}
                />
                {/*<HaveEyeIconField label={translate('commons.image')}/>*/}
                <ViewImage
                    label={translate("commons.image")}
                    source="image"
                    containerId="reserves"
                    type="LIST"
                />
                <ReferenceField
                    source="accountId"
                    reference="Accounts"
                    label={translate("commons.driver")}
                    headerClassName="w-8"
                >
                    <TextField source="nom"/>
                </ReferenceField>
                <ReferenceField
                    source="etapeId"
                    reference="Etapes"
                    label={translate("commons.etape")}
                >
                    <TextField source="id"/>
                </ReferenceField>
                {/*<CustomShowData label={translate('commons.informationTrip')} headerClassName="w-10 p-sm" className="limit-text" cellClassName={"p-sm"}/>*/}
                <TextField
                    source="tripInformation"
                    label={translate("commons.informationTrip")}
                    headerClassName="w-15 p-sm"
                    cellClassName={"p-sm"}
                />
                <TextField
                    source="note"
                    label={translate("commons.note")}
                    headerClassName="w-15 p-sm"
                    cellClassName={"p-sm"}
                />
                <CustomLatLng
                    source="latitude"
                    label={translate("commons.latitude")}
                    headerClassName="p-sm"
                    cellClassName={"p-sm"}
                />
                <CustomLatLng
                    source="longitude"
                    label={translate("commons.longitude")}
                    headerClassName="p-sm"
                    cellClassName={"p-sm"}
                />
                <TextField
                    source="timeStamp"
                    label={translate("commons.timeStamp")}
                    headerClassName="w-10 p-sm"
                    cellClassName={"p-sm"}
                />
                <TextField
                    source="hdop"
                    label={translate("commons.hdop")}
                    headerClassName="p-sm"
                    cellClassName={"p-sm"}
                />
                <TextField
                    source="altitude"
                    label={translate("commons.altitude")}
                    headerClassName="p-sm"
                    cellClassName={"p-sm"}
                />
                <TextField
                    source="speed"
                    label={translate("commons.speed")}
                    headerClassName="p-sm"
                    cellClassName={"p-sm"}
                />
                <DateField source="createdAt" label={translate('commons.createdAt')} showTime={true}
                           headerClassName="p-sm" cellClassName={"p-sm"}/>
                {/*<DateField source="updatedAt" label={translate('commons.updatedAt')} headerClassName="p-sm" cellClassName={"p-sm"}/>*/}
                {/*<EditButton className="btn-edit"/>*/}
            </Datagrid>
        </List>
    );
};

export const ReseveCreate = (props: any) => {
    const translate = useTranslate();
    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mt-3">
                {/* <Link to="/Reseves" className="text-decoration-none">
          <ArrowBackIcon /> {translate("button.back")}
        </Link> */}
                <BackButton/>
                <h3>{translate("reseve.createReseve")}</h3>
                <b></b>
            </div>
            <Create {...props}>
                <SimpleForm redirect="/Reseves">
                    <CustomReseveForm/>
                </SimpleForm>
            </Create>
        </div>
    );
};

export const ReseveEdit = (props: any) => {
    const translate = useTranslate();
    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mt-3">
                {/* <Link to="/Reseves" className="text-decoration-none">
          <ArrowBackIcon /> {translate("button.back")}
        </Link> */}
                <BackButton/>
                <h3>{translate("reseve.editReseve")}</h3>
                <b></b>
            </div>
            <Edit {...props}>
                <SimpleForm redirect="/Reseves" className="w-100">
                    <CustomReseveForm isEdit={true}/>
                </SimpleForm>
            </Edit>
        </div>
    );
};
