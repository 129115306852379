import {
    Create,
    Datagrid, DateField,
    Edit,
    List,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput, useTranslate
} from 'react-admin';
import React from 'react';
import { parse } from "query-string";

export const RolemappingList = (props: any) => (
    <List {...props}
          sort={{ field: "updatedAt", order: "DESC" }}
    >
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="principalType"/>
            <ReferenceField source="principalId" reference="Accounts">
                <TextField source="username"/>
            </ReferenceField>
            <ReferenceField source="roleId" reference="Roles">
                <TextField source="name"/>
            </ReferenceField>
            <DateField source="updatedAt"/>
        </Datagrid>
    </List>
);

export const EditRoleMapping = (props: any) => {
    const translate = useTranslate();
    const { principalId: post_id_string } = parse(props.location.search);
    const principalId = post_id_string;
    const redirect = principalId ? `/Accounts/${principalId}/roles` : false;
    const handleSubmit = (value) => {
        console.log(value);
    };
    return (
        <Edit {...props}>
            <SimpleForm redirect={redirect} on={(data) => {handleSubmit(data)}}>
                <TextInput disabled source="principalType" label={translate('commons.principalType')} />
                <ReferenceInput source="principalId" reference="Accounts" label={translate('commons.principal')}>
                    <SelectInput disabled={principalId ? true : null} optionText="username"/>
                </ReferenceInput>
                <ReferenceInput source="roleId" reference="Roles" label={translate('commons.roles')}>
                    <SelectInput optionText="name"/>
                </ReferenceInput>

            </SimpleForm>
        </Edit>
    );
};

export const CreateRoleMapping = (props: any) => {
    const translate = useTranslate();
    const { principalId: post_id_string } = parse(props.location.search);
    const principalId = post_id_string;
    const redirect = principalId ? `/Accounts/${principalId}/edit/roles` : false;

    return (
        <Create {...props}>
            <SimpleForm initialValues={{ principalId }}
                        redirect={redirect}>
                <TextInput source="principalType" initialValue="USER" label={translate('commons.principalType')}/>
                <ReferenceInput source="principalId" reference="Accounts" label={translate('commons.principal')}>
                    <SelectInput disabled={principalId ? true : null} optionText="username"/>
                </ReferenceInput>
                <ReferenceInput source="roleId" reference="Roles" label={translate('commons.roles')}>
                    <SelectInput optionText="name"/>
                </ReferenceInput>
            </SimpleForm>
        </Create>
    );
};
