import React, { useEffect, useState } from 'react';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { ImageField } from 'react-admin';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { ShowImages } from '../components/show-images';
import { API_URL } from '../variables/constant';
import { LoopbackAPI } from '../react-admin-loopback/api/loopback-api';
import Carousel from 'react-material-ui-carousel'


export const ViewImage = (props) => {
    const [open, setOpen] = useState(false);

    const useStyles = makeStyles({
        iconEyes: {
            color: 'rgb(204, 204, 0)',
            fontSize: 32,
            cursor: 'pointer'
        },
        disabled: {
            opacity: 1
        },
        wrapImage: {
            width: 500,
            height: 600
        },
        img: {
            width: '100%',
            height: '100%',
            objectFit: 'contain'
        },
        root: {
            padding: 0
        }
    });
    const classes = useStyles();

    const handleClose = () => {
        setOpen(false);
    }
    const handleClickOpen = (e) => {
        e.stopPropagation();
        if (!props?.record?.anomalieIm1) {
        }  if (!props?.record?.anomalieIm2) {
        }  if (!props?.record?.anomalieIm3) {
        }
        setOpen(true);
    };
    const ImagesShow = (props) => {
        return props?.type === 'LIST' ? (
            props?.images?.length > 0 ? (
                <Carousel autoPlay={false}>
                    {props?.images.map((src, index) => (
                        <div className={classes.wrapImage} key={index}>
                            <img src={`${API_URL}/Containers/${props?.containerId}/download/${src}`} alt="" className={classes.img}/>
                        </div>
                    ))}
                </Carousel>
            ) : null
        ) : (
            <div className={classes.wrapImage}>
                <img src={`${API_URL}/Containers/${props?.containerId}/download/${props?.images}`} alt="" className={classes.img}/>
            </div>
        );
    };
    return (
        <div>
            <VisibilityOutlinedIcon onClick={handleClickOpen}
                                    className={`${!props?.record?.anomalieIm1 ||  !props?.record?.anomalieIm1 || !props?.record?.anomalieIm3 ? classes.disabled : null} ${classes.iconEyes}`}/>
            <Dialog
                maxWidth="md"
                open={open}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
                classes={{ root: classes.root } }
            >
                <DialogContent>
                    <ImagesShow containerId={props?.containerId} images={props?.record?.anomalieIm1 || props?.record?.anomalieIm2 || props?.record?.anomalieIm3} type={props?.type}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
